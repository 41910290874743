import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
import { ErrorMessages } from '../TS-files/errorMessages';

@Injectable({
  providedIn: 'root',
})
export class ShowErrorService {
  constructor(private errorMessage: ErrorMessages) {}

  /***************************************************************************
	@PURPOSE      : To show validation message
	@PARAMETERS   : field_name, errorObj?
	@RETURN       : error message
	****************************************************************************/
  showError(field, errorObj?) {
    return this.errorMessage.getError(field, errorObj);
  }
  /****************************************************************************/

  /**************************************************************************
	@PURPOSE      : We can use following function to use Toaster Service.
	@PARAMETERS   : field_name, errorObj?
	@RETURN       : error message
	/**************************************************************************/
  popToast(type, title) {
    swal({
      position: 'center',
      type,
      text: title,
      showConfirmButton: false,
      timer: 2000,
      customClass: 'custom-toaster',
    });
  }

  /**************************************************************************
	@PURPOSE      : We can use following function to use Toaster Service.
	@PARAMETERS   : field_name, errorObj?
	@RETURN       : error message
	/**************************************************************************/
  popToastWithoutTimer(type, title) {
    swal({
      position: 'center',
      type,
      text: title,
      showConfirmButton: true,
      customClass: 'custom-toaster',
    });
  }

  confirmpopToast(type, title, btnText) {
    return swal({
      // title: 'Are you sure?',
      text: title,
      type,
      showCancelButton: true,
      confirmButtonText: btnText,
      confirmButtonClass: 'btn btn-secondary',
      cancelButtonClass: 'btn btn-secondary',
    });
  }
  /*************************************************************************/
}
