import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchService {
  //Variable Declaration
  public globalSearch = new Subject<any>();
  public setproperty = new Subject<any>();
  constructor() {}

  /*************************************************************
        @Purpose : To send the universal search value
    //*************************************************************/
  sendGlobalSearch(message: string) {
    this.globalSearch.next({ text: message });
  }

  getGlobalSearchInfo(): Observable<any> {
    return this.globalSearch.asObservable();
  }
}
