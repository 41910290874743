<!-- <ng-template #postdetailpopup>  -->
<div class="custom-model">
  <button
    type="button"
    class="media-model-close"
    aria-label="Close"
    (click)="modal.hide()"
  >
    <span class="icon-close close-icon-size"></span>
  </button>
  <div class="modal-body">
    <div class="feed-left" *ngIf="modalData.type === 'feed'">
      <div class="feedheader">
        <div class="feed-user d-flex align-items-center">
          <picture class="profile-img">
            <img
              [src]="modalData.details.photo"
              alt="no-image"
              (error)="img.src = uploadedImage"
              #img
            />
          </picture>
          <div class="ml-3">
            <h4 class="profile-name font-semibold mb-0">
              {{
                modalData.details.author.fakeUsername
                  ? modalData.details.author.fakeUsername
                  : '--'
              }}
            </h4>
            <p class="post-date mb-0">
              {{ modalData.details.createdAt | date : 'd MMM yyyy' }}
            </p>
          </div>
        </div>
        <p class="font-bold mt-2 mb-0 pr-0 profile-desc">
          {{
            modalData.details.incidentTitle
              ? modalData.details.incidentTitle
              : '--'
          }}
        </p>
      </div>
      <!-- feed header -->
      <div
        class="feed-action-block pt-0"
        [ngClass]="{ limitTextHeight: isReadMore }"
      >
        <p class="font-medium">
          {{
            modalData.details.incidentDescription
              ? modalData.details.incidentDescription
              : '--'
          }}
          <strong *ngFor="let tag of modalData.details.tags">{{
            tag.name ? tag.name : ''
          }}</strong
          >.
        </p>
      </div>
      <!-- Tags section -->
      <div class="social-action pt-3">
        <div class="like-comment" [ngClass]="shakeLike && 'shake-like'">
          <span
            class="icon-like"
            [ngClass]="modalData.details.youLiked ? 'active' : ''"
            (click)="updateFeedsocialData(modalData.details._id, true)"
          ></span>
        </div>
        <!-- <div
          *ngIf="
            modalData.details.isOpenInteraction ||
            modalData.details.industry === logedUserData.industry
          "
        >
          <span
            class="icon-comment"
            (click)="redirectToComment(modalData.details._id)"
          ></span>
        </div> -->
        <div class="mr-auto">
          <span
            class="icon-forward share"
            (click)="Sharemodel(sharemodel, modalData.details)"
          ></span>
        </div>
        <div [ngClass]="pulse && 'pulse-hover'">
          <span
            class="icon-heart"
            [ngClass]="modalData.details.isFavorite ? 'active' : ''"
            (click)="updateFeedsocialData(modalData.details._id, false)"
          ></span>
        </div>
      </div>
      <!-- social ison section -->
      <div class="social-action-count">
        <div>
          <span class="like-count">
            <strong [ngClass]="modalData.details.youLiked ? 'active' : ''">{{
              modalData.details.likes ? modalData.details.likes : '0'
            }}</strong>
            {{
              modalData.details.likes == 1
                ? ('Like' | translate)
                : ('Likes' | translate)
            }}
          </span>
        </div>
        <div class="mr-auto">
          <span class="comments-count">
            <strong>{{
              modalData.details.comments?.length
                ? modalData.details.comments?.length
                : '0'
            }}</strong>
            {{
              modalData.details.comments?.length == 1
                ? ('Comment' | translate)
                : ('Comments' | translate)
            }}
          </span>
        </div>
        <div>
          <a
            class="view_more font-semibold"
            href="javascript:;"
            *ngIf="modalData.details.incidentDescription.length > 173"
            (click)="showText()"
            >{{
              isReadMore ? ('Read More' | translate) : ('Read Less' | translate)
            }}</a
          >
        </div>
      </div>
    </div>
    <div
      class="feed-right"
      [ngClass]="modalData.type === 'feed' ? '' : 'mw-100'"
    >
      <div
        class="detail-slider"
        [ngClass]="modalData.type === 'feed' ? '' : 'border-18'"
      >
        <owl-carousel-o [options]="CommnetDetail">
          <ng-template
            carouselSlide
            *ngFor="let mediaData of modalData.data; let ind = index"
          >
            <div class="feed-slide-img">
              <picture [ngClass]="modalData.type === 'feeds' ? '' : 'w-100'">
                <img
                  width="780"
                  height="660"
                  [src]="mediaData.filePath"
                  alt="Media"
                  *ngIf="mediaData.fileType == 'image'"
                  (error)="img.src = noImage"
                  #img
                />
                <video
                  class="w-100"
                  id="mediaVideo{{ ind }}"
                  controls
                  (playing)="startVidPlaying(ind)"
                  controlsList="nodownload"
                  [muted]="true"
                  playsinline
                  *ngIf="mediaData.fileType == 'video'"
                >
                  <source [src]="mediaData.filePath" />
                </video>
              </picture>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</div>
<!-- </ng-template> -->

<div class="custom-model">
  <ng-template #sharemodel>
    <div class="modal-body-wrapper">
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="shareRef.hide()"
      >
        <span aria-hidden="true">×</span>
      </button>
      <div class="common-form p-4">
        <h2 class="text-center form-title mt-md-4 mt-2 mb-3">
          {{ 'Share' | translate }}
        </h2>
        <div class="feedheader p-0">
          <div class="feed-user p-0 d-flex align-items-center">
            <picture class="profile-img">
              <img
                [src]="shareIncidentData.author?.photo"
                alt="no-img"
                (error)="img.src = uploadedImage"
                #img
              />
            </picture>
            <div class="ml-3 mr-auto">
              <h4 class="profile-name font-semibold mb-0">
                {{
                  shareIncidentData.author.fakeUsername
                    ? shareIncidentData.author.fakeUsername
                    : '--'
                }}
              </h4>
              <p class="post-date mb-0">
                {{ shareIncidentData.createdAt | date : 'dd MMM yyyy' }}
              </p>
            </div>
          </div>
          <p class="font-bold mt-2 mb-0 profile-desc">
            {{
              shareIncidentData.incidentTitle
                ? shareIncidentData.incidentTitle
                : ''
            }}
          </p>
          <!-- <a class="text-black my-md-4 my-3 d-block share-link line-height-normal" target="_blank" href="http:dicerra.com/funnaymunoz7923242848-0/hei53554w/
fdjfofdsjfjp4334/?fsfsf=winterfs">http:dicerra.com/funnaymunoz7923242848-0/hei53554w/
                        fdjfofdsjfjp4334/?fsfsf=winterfs</a> -->
          <!-- <div class="text-center">
                        <button class="btn  btn-transparent d-inline-flex align-items-center copy-btn font-bold"><span
                                class="icon-copy mr-3 pink-color-icon "></span>Copy link</button>
                        <p class="font-bold my-3 my-md-4 ">OR Share with</p>
                    </div> -->
          <div class="d-flex justify-content-center mt-5">
            <share-buttons
              [theme]="'modern-light'"
              [include]="[
                'facebook',
                'twitter',
                'whatsapp',
                'linkedin',
                'copy'
              ]"
              [show]="9"
              [size]="1"
              [autoSetMeta]="false"
              [url]="'http://dicerrastaging.node.indianic.com/feed'"
            >
            </share-buttons>
          </div>
          <div>
            <!-- <button class="btn btn-secondary w-100 mt-md-5 mt-4 mb-2">Share this incident</button> -->
          </div>
        </div>
        <!-- feed header -->
      </div>
    </div>
  </ng-template>
</div>
<!-- Share popup -->
