export const MetaConstants = {
  meta_data_home: {
    title: 'Dicerra – Log in or sign up',
    keywords:
      'anonymous, confidential, healthcare, digital health, doctors, nurses, digital health platform, healthcare forum, hospital incident reporting',
    description:
      'Connect with other medical professionals on our anonymous platform. Collaborate with healthcare workers of all kinds to improve the standard of care everywhere.',
  },
  meta_data_about: {
    title: 'Dicerra – For all healthcare professionals',
    keywords:
      'anonymous, confidential, healthcare, digital health, doctors, nurses, digital health platform, healthcare forum, hospital incident reporting',
    description:
      'Trusted by healthcare workers who need an anonymous place to share. See trends in healthcare. Discuss issues and concerns with a community of medical professionals.',
  },
  meta_data_service: {
    title: 'Service',
    keywords: 'Our Services',
    description: 'service, service we follow in our website',
  },
  meta_data_contact: {
    title: 'Dicerra - Have a query?',
    keywords:
      'anonymous, confidential, healthcare, digital health, doctors, nurses, digital health platform, healthcare forum, hospital incident reporting',
    description:
      'Message us directly or email us at your convenience. Tell us what you think.',
  },
  meta_data_feed: {
    title: 'Feed',
    keywords: 'Feed',
    description: 'feed, contact our website',
  },
  meta_data_profile: {
    title: 'My Profile',
    keywords: 'My Profile',
    description: 'My Profile, contact our website',
  },
  meta_data_my_contribution: {
    title: 'My Contribution',
    keywords: 'My Contribution',
    description: 'My Contribution, contact our website',
  },
  meta_data_incident: {
    title: 'Incident',
    keywords: 'Incident',
    description: 'Incident, contact our website',
  },
  meta_data_notification: {
    title: 'Notification',
    keywords: 'Notification',
    description: 'Notification, contact our website',
  },
  meta_data_view_incident: {
    title: 'View Incident',
    keywords: 'View Incident',
    description: 'View Incident, contact our website',
  },
  meta_data_settings: {
    title: 'Settings',
    keywords: 'Settings',
    description: 'Settings, contact our website',
  },
  meta_data_faq: {
    title: 'FAQ',
    keywords: 'FAQ',
    description: 'FAQ, contact our website',
  },
  meta_data_privacy_policy: {
    title: 'Privacy Policy',
    keywords: 'Privacy Policy',
    description: 'Privacy Policy, contact our website',
  },
  meta_data_terms_conditions: {
    title: 'Terms & Conditions',
    keywords: 'Terms & Conditions',
    description: 'Terms & Conditions, contact our website',
  },
  meta_data_edit_profile: {
    title: 'Edit Profile',
    keywords: 'Edit Profile',
    description: 'Edit Profile, contact our website',
  },
};
