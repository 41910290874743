<div class="d-flex flex-column h-100">
  <!-- header -->
  <app-header *ngIf="isLogin"></app-header>
  <!-- header -->
  <div class="flex-shrink-0">
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      color="#dc3163"
      size="medium"
      type="ball-spin"
      [fullScreen]="true"
    >
      <p style="color: white">Loading...</p>
    </ngx-spinner>
    <div [ngClass]="[showTopPadding ? 'pt-5' : '']">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- footer -->

  <div class="mt-auto">
    <app-footer *ngIf="isFooter"></app-footer>
  </div>
  <!-- footer -->
</div>
