import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Shared
import { SharedModule } from './shared/shared/shared.module';

// Services
import { ServiceModule } from './common/services/service.module';

// Component
import { AppComponent } from './app.component';
// import { CustomPipe } from './common/services/service/customPipe.pipe';

import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './common/services/TS-files/route-reuse-strategy';
import {
  TranslateModule,
  TranslateService,
  TranslateLoader,
  TranslateStore,
} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import {
  ApplicationInitializerFactory,
  HttpLoaderFactory,
} from './translation.config';

@NgModule({
  declarations: [
    AppComponent,
    // CustomPipe
  ],
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    ServiceModule,
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
