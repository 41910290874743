import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  public scrollToTop = new Subject<any>();
  constructor() {}

  sendScrollEvent() {
    this.scrollToTop.next();
  }

  getScrollService(): Observable<any> {
    return this.scrollToTop.asObservable();
  }
}
