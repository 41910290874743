<form [formGroup]="addSolution" (ngSubmit)="submitSolution()">
  <div>
    <div class="col-12">
      <div class="form-group">
        <label class="sr-only" for="addtitle2">{{
          'Add title' | translate
        }}</label>
        <input
          type="text"
          class="form-control extra-bg-color-input"
          id="addtitle2"
          placeholder="{{ 'Add title' | translate }}"
          formControlName="solutionTitle"
          required
          #solutionTitleInput
        />
        <span
          class="error-msg"
          *ngIf="
            (submitted || addSolution.controls.solutionTitle.touched) &&
            addSolution.controls.solutionTitle.invalid
          "
        >
          {{
            showErrorService.showError(
              'solutionTitle',
              addSolution.controls.solutionTitle.errors
            )
          }}
        </span>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label class="sr-only" for="adddesc">{{
          'Add Description' | translate
        }}</label>
        <textarea
          class="form-control extra-bg-color-input"
          id="adddesc"
          placeholder="{{ 'Add Description' | translate }}"
          rows="{{ isIncidentDetail ? 5 : 7 }}"
          formControlName="solutionDescription"
          #solutionDescription
        ></textarea>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label class="sr-only" for="adddesc">{{ 'Tags' | translate }}</label>
        <ng-select
          class="custom-slectmenu with-tag"
          [ngClass]="isIncidentDetail ? 'custom-select-class-solution' : ''"
          [items]="tagListArray"
          bindLabel="name"
          bindValue="_id"
          placeholder="{{ 'Tag for Solution' | translate }}"
          [multiple]="true"
          formControlName="solutionTags"
        >
          <ng-template ng-option-tmp let-item="item">
            <div
              container="body"
              class="text-left"
              tooltip="{{ item.definition }}"
              title="{{ item.name }}"
              placement="right"
            >
              {{ item.name }}
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>

    <!-- Add Images or Videos -->
    <div class="col-12">
      <h4 class="mb-3 mt-3 mt-md-4">
        {{ 'Add Images or Videos' | translate }}
      </h4>
    </div>
    <div class="col-md-12">
      <div class="upload-img-list d-flex flex-wrap flex-md-row mt-2">
        <div class="form-group custom-upload-list mr-3 mb-3 mb-0">
          <label class="sr-only" for="imageuploadlist">
            {{ 'file or Drag & drop image heregg.' | translate }}
          </label>
          <input
            type="file"
            class="form-control-file"
            id="imageuploadlist"
            (change)="onFileSelected($event, 'solution')"
          />
          <span class="icon-plus icon-blue-color"></span>
        </div>
        <!-- Uploade field -->

        <ng-container *ngIf="solutionImageAndVideos.length">
          <div
            class="uploaded-img mr-3 mb-3"
            *ngFor="let data of solutionImageAndVideos; let ind = index"
          >
            <span
              class="delete-item icon-close"
              (click)="removeData(data, ind, 'solution')"
            ></span>
            <picture>
              <img
                width="76"
                height="76"
                [src]="data.path"
                alt="upload Image"
                (error)="img.src = uploadedImage"
                #img
                *ngIf="data.type == 'image'"
              />
              <video
                class="w-100 video-strach"
                id="solutionVideo{{ ind }}"
                style="height: 100%"
                controlsList="nodownload"
                [muted]="true"
                playsinline
                *ngIf="data.type == 'video'"
              >
                <source [src]="data.path" />
              </video>
            </picture>
          </div>
        </ng-container>
      </div>
      <p class="" *ngIf="inputData === 'Add Incident'">
        {{
          'By default, Your content will be posted anonymously, you can change this if you wish'
            | translate
        }}
      </p>
    </div>
  </div>

  <div class="d-flex flex-column flex-md-row justify-content-center mt-3">
    <button
      *ngIf="!isIncidentDetail"
      type="button"
      class="btn btn-primary mb-3 btn-transparent mr-md-2 w-100"
      (click)="model.hide()"
    >
      {{ 'Cancel' | translate }}
    </button>
    <button
      type="submit"
      class="btn btn-primary mb-3 ml-md-2 w-100"
      [disabled]="isLoading"
    >
      <span
        *ngIf="isLoading"
        class="spinner-border spinner-border-sm mr-2"
      ></span>
      {{ isLoading ? ('Submitting' | translate) : ('Submit' | translate) }}
    </button>
  </div>
</form>
