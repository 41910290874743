import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class GlobalLanguageService {
  public globalLanguage = new Subject<any>();
  constructor() {}

  sendGlobalLanguage(message: any) {
    this.globalLanguage.next({ data: message });
  }

  getGlobalLanguage(): Observable<any> {
    return this.globalLanguage.asObservable();
  }
}
