import { Component } from '@angular/core';
import {
  ActivatedRoute,
  Event as RouterEvent,
  NavigationEnd,
  Router,
} from '@angular/router';
import * as _ from 'lodash';
import { callAPIConstants } from './common/constants/callAPI-constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Constants
import { URLConstants } from './common/constants/routerLink-constants';
import { CommonService } from './common/services/service/common.service';
import { LocalStorageService } from './common/services/service/local-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  URLConstants = URLConstants;
  public isLogin: boolean = false;
  public isFooter: boolean = false;
  public currentRouteURL: any;
  public callAPIConstants = callAPIConstants;
  public userData: any;
  public showTopPadding: boolean = true;
  public sessionAnalyticsToken: String = null;
  constructor(
    public router: Router,
    public localStorageService: LocalStorageService,
    public commonService: CommonService,
    private route: ActivatedRoute,
    public Http: HttpClient
  ) {
    let routesArray = ['reset-password', 'links'];
    let topPaddigArray = [
      'home',
      'about',
      'contact',
      '/',
      'terms-conditions',
      'privacy-policy',
      'reset-password',
    ];
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        let currentURL = event.url;
        let newRoute = _.toString(_.split(currentURL, '/')[1]) || '/';
        let latestRoute = _.toString(_.split(newRoute, '?')[0]) || '?';
        this.currentRouteURL = latestRoute;
        if (routesArray.indexOf(latestRoute) > -1) {
          this.isLogin = false;
          this.isFooter = false;
        } else {
          this.isLogin = true;
        }

        if (topPaddigArray.indexOf(latestRoute) > -1) {
          this.showTopPadding = false;
        } else {
          this.showTopPadding = true;
        }

        if (this.localStorageService.getToken('accessToken')) {
          // this.getNotificationCount();
        }

        const qr = this.route.snapshot.queryParamMap.get('qr');
        const globalSignUp =
          this.route.snapshot.queryParamMap.get('globalSignup');
        if (qr != null) {
          this.updateQrCampaignVisits(qr);
        }

        if (globalSignUp == null && this.sessionAnalyticsToken != null) {
          this.updatePerPageAnalytics(this.sessionAnalyticsToken, latestRoute);
        }
      }
    });

    this.Http.get('https://api.ipify.org/?format=json').subscribe(
      (value) => {
        this.localStorageService.setToken('ip', (value as any).ip);
      },
      (error) => {}
    );
  }

  ngOnInit() {
    this.sessionAnalyticsToken = window.sessionStorage.getItem(
      'sessionAnalyticsToken'
    );
  }

  /****************************************************************************
          @Purpose     : getNotificationCount
          @Parameter   : Na
          @Return      : Na
  /****************************************************************************/
  getNotificationCount() {
    this.commonService
      .callApi(
        this.callAPIConstants.getNotificationListCount,
        '',
        'get',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          this.userData = JSON.parse(
            this.localStorageService.getToken('currentUser')
          );
          this.userData.notificationCount = success.notificationCount;
          this.localStorageService.setToken(
            'currentUser',
            JSON.stringify(this.userData)
          );
          // this.localStorageService.setToken('industry', this.userData.industry);
        }
      });
  }

  /****************************************************************************
          @Purpose     : send analytics
          @Parameter   : Na
          @Return      : Na
  /****************************************************************************/
  updateQrCampaignVisits(token) {
    this.commonService
      .callApi(
        this.callAPIConstants.updateQrVisits,
        { token: token, type: 'view' },
        'post',
        true,
        false
      )
      .then((success) => {
        this.router.navigate([], {
          queryParams: {
            qr: null,
          },
          queryParamsHandling: 'merge',
        });

        this.sessionAnalyticsToken = token;
        window.sessionStorage.setItem('sessionAnalyticsToken', token);
      });
  }

  /****************************************************************************
          @Purpose     : send analytics per page
          @Parameter   : Na
          @Return      : Na
  /****************************************************************************/
  updatePerPageAnalytics(token, page) {
    this.commonService
      .callApi(
        this.callAPIConstants.updatePerPageVisits,
        { token: token, page: page },
        'post',
        true,
        false
      )
      .then((success) => {
        this.router.navigate([], {
          queryParams: {
            qr: null,
          },
          queryParamsHandling: 'merge',
        });

        this.sessionAnalyticsToken = token;
      });
  }

  ngDoCheck() {
    if (
      !this.localStorageService.getToken('accessToken') &&
      !(this.currentRouteURL === 'reset-password') &&
      !(this.currentRouteURL === 'links')
      // && !(this.currentRouteURL === 'publicfeed')
    ) {
      this.isFooter = true;
    } else {
      this.isFooter = false;
    }
  }
}
