import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fromEvent } from 'rxjs';
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
} from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { CommonService } from 'src/app/common/services/service/common.service';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MetaDataService } from 'src/app/common/services/service/meta-data.service';
import { GlobalLanguageService } from 'src/app/common/services/service/global-language.service';
import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';

@Component({
  selector: 'app-give-solutions',
  templateUrl: './give-solutions.component.html',
  styles: [],
})
export class GiveSolutionsComponent implements OnInit {
  //variable Declaration
  @Input() isLoading: boolean = false;
  @Input() inputData: any;
  @Input() model: any;
  @Input() isIncidentDetail: boolean = true;
  @Output() solutionData: any = new EventEmitter();
  @Output() editDone: any = new EventEmitter();
  public uploadedImage: any = 'assets/images/image.png';
  public tagListArray: Array<any> = [];
  public submitted: boolean = false;
  public addSolution: FormGroup;
  public finalSolutionData: any;
  public filesize: any;
  public userIncidentFileData: any = {};
  public userPhotoAndVideoData: Array<any> = [];
  public solutionImageAndVideos: Array<any> = [];
  public userIncidentFilePath: Array<any> = [];
  public userSolutionFilePath: Array<any> = [];
  public callAPIConstants = callAPIConstants;
  public URLConstants = URLConstants;
  @Input() editFlag: boolean = false;
  @Input() incidentId: any = '';
  @Input() solutionId: any = '';
  @ViewChild('solutionTitleInput', { static: false })
  solutionTitleInput: ElementRef;
  @ViewChild('solutionDescription', { static: false })
  solutionDescription: ElementRef;
  public localLanguage: any;
  public germanTagListArrayTemp: Array<any> = [];
  public englishTagListArrayTemp: Array<any> = [];
  public frenchTagListArrayTemp: Array<any> = [];

  constructor(
    private formBuilder: FormBuilder,
    public spinnerService: NgxSpinnerService,
    public commonService: CommonService,
    public showErrorService: ShowErrorService,
    public globalLanguageService: GlobalLanguageService,
    public localStorageService: LocalStorageService
  ) {
    this.localLanguage = localStorageService.getToken('language');
  }

  ngOnInit(): void {
    this.getIncidentData();
    this.addSolution = this.formBuilder.group({
      solutionTitle: ['', [Validators.required]],
      solutionDescription: [''],
      solutionTags: [[]],
    });
    if (this.editFlag) {
      this.getSolutionDetails();
    }

    this.globalLanguageService.getGlobalLanguage().subscribe((value) => {
      this.localLanguage = value.data;
      if (this.localLanguage == 'en') {
        this.tagListArray = this.englishTagListArrayTemp;
      } else if (this.localLanguage == 'de') {
        this.tagListArray = this.germanTagListArrayTemp;
      } else if (this.localLanguage == 'fr') {
        this.tagListArray = this.frenchTagListArrayTemp;
      }
    });
  }

  ngAfterViewInit() {
    // solution title
    fromEvent(this.solutionTitleInput.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(1500),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.tagListArray.map((tag) => {
            if ('keywords' in tag)
              tag.keywords.map((keyword) => {
                const regex = new RegExp(`\\b${keyword.keyword}\\b`, 'gi');
                if (this.solutionTitleInput.nativeElement.value.match(regex)) {
                  let tagArr = this.addSolution.value.solutionTags;
                  if (!tagArr.includes(tag._id)) {
                    tagArr.push(tag._id);
                    this.addSolution.get('solutionTags').setValue(tagArr);
                  }
                }
              });
          });
        })
      )
      .subscribe();

    // solution description
    fromEvent(this.solutionDescription.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(1500),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.tagListArray.map((tag) => {
            if ('keywords' in tag)
              tag.keywords.map((keyword) => {
                const regex = new RegExp(`\\b${keyword.keyword}\\b`, 'gi');
                if (this.solutionDescription.nativeElement.value.match(regex)) {
                  let tagArr = this.addSolution.value.solutionTags;
                  if (!tagArr.includes(tag._id)) {
                    tagArr.push(tag._id);
                    this.addSolution.get('solutionTags').setValue(tagArr);
                  }
                }
              });
          });
        })
      )
      .subscribe();
  }
  /****************************************************************************
         @Purpose     : getIncidentData
         @Parameter   : Na
         @Return      : Na
    /****************************************************************************/
  getIncidentData() {
    this.commonService
      .callApi(this.callAPIConstants.getIncidentData, '', 'get', false, false)
      .then((success) => {
        if (success.status === 1) {
          // this.tagListArray = success.data.tagList;
          this.germanTagListArrayTemp = success.data.tagListGerman;
          this.englishTagListArrayTemp = success.data.tagList;
          this.frenchTagListArrayTemp = success.data.tagListFrench;
          if (this.localLanguage === 'en') {
            this.tagListArray = this.englishTagListArrayTemp;
          } else if (this.localLanguage === 'de') {
            this.tagListArray = this.germanTagListArrayTemp;
          } else if (this.localLanguage === 'fr') {
            this.tagListArray = this.frenchTagListArrayTemp;
          }
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
  }

  /****************************************************************************
                          @Purpose     : getSolutionDetails
                          @Parameter   : Na
                          @Return      : Na
    /****************************************************************************/
  getSolutionDetails() {
    let data = {
      solutionId: this.solutionId,
      incidentId: this.incidentId,
    };
    this.commonService
      .callApi(this.callAPIConstants.getSolutionById, data, 'post')
      .then((success) => {
        if (success.status === 1) {
          const solution = success.data.solution;
          this.addSolution.get('solutionTitle').setValue(solution.title);
          this.addSolution
            .get('solutionDescription')
            .setValue(solution.description);
          let tags = this.getIds(solution.tags);
          this.addSolution.get('solutionTags').setValue(tags);
          this.userSolutionFilePath = solution.media.map((mediaData) => {
            if (mediaData.filePath.includes('images/')) {
              var updatedFilePath =
                _.toString(_.split(mediaData.filePath, 'images/')[1]) ||
                'images/';
            } else {
              var updatedFilePath =
                _.toString(_.split(mediaData.filePath, 'videos/')[1]) ||
                'videos/';
            }
            var file = {
              filePath: updatedFilePath,
              fileType: mediaData.fileType,
            };
            return (mediaData = file);
          });

          this.solutionImageAndVideos = solution.media.map((obj) => {
            obj.path = obj.filePath;
            obj.type = obj.fileType;
            return obj;
          });
          console.log(this.solutionImageAndVideos);
        }
      });
  }

  /****************************************************************************
         @Purpose     : getIds
         @Parameter   : arr
         @Return      : Na
  /****************************************************************************/
  getIds(arr) {
    if (arr.length) {
      let data = arr.map((obj) => {
        return obj._id;
      });
      return data;
    } else {
      return [];
    }
  }

  /****************************************************************************
     @Purpose     : Addincident
     @Parameter   : Na
     @Return      : Na
  /****************************************************************************/
  editSolution() {
    let editSolution = {
      id: this.solutionId,
      title: this.addSolution.value.solutionTitle
        ? this.addSolution.value.solutionTitle
        : '',
      description: this.addSolution.value.solutionDescription
        ? this.addSolution.value.solutionDescription
        : '',
      tags: this.addSolution.value.solutionTags
        ? this.addSolution.value.solutionTags
        : '',

      media: this.userSolutionFilePath ? this.userSolutionFilePath : [],
    };

    this.spinnerService.show();
    this.commonService
      .callApi(
        this.callAPIConstants.updatedSolution,
        editSolution,
        'post',
        false,
        false
      )
      .then((success) => {
        this.spinnerService.hide();
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          this.editDone.emit();
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
  }

  /****************************************************************************
       @Purpose     : submitSolution
       @Parameter   : Na
       @Return      : Na
  /****************************************************************************/
  submitSolution() {
    this.submitted = true;
    if (this.addSolution.valid) {
      this.finalSolutionData = this.addSolution.value;
      this.finalSolutionData['solutionMedia'] = this.userSolutionFilePath;
      if (this.editFlag) {
        this.editSolution();
      } else {
        this.solutionData.emit(this.finalSolutionData);
      }
      this.addSolution.reset();
      this.solutionImageAndVideos = [];
      this.submitted = false;
    }
  }

  /****************************************************************************
     @Purpose     : OnFileUpload
     @Parameter   : Event,typeFile
     @Return      : Na
  /****************************************************************************/
  onFileSelected(event, typeFile) {
    if (event.target.files.length) {
      var input = event.target;
      var allowedExtensions =
        /(\.jpg|\.jpeg|\.png)|\.mp4|\.avi|.mov|.mpg|.3gp|.asf$/i;
      this.filesize = event.target.files[0].size / 1024 / 1024 + 'MB';
      if (
        allowedExtensions.exec(event.target.value) &&
        this.solutionImageAndVideos.length < 10
      ) {
        var input = event.target;
        var reader = new FileReader();

        reader.onload = (e: any) => {
          this.userIncidentFileData.uploadedPhoto = input.files[0];
          var fd = new FormData();
          if (input.files[0].type.includes('video')) {
            this.userIncidentFileData.Type = 'video';
            event.srcElement.value = '';
          } else {
            this.userIncidentFileData.Type = 'image';
            event.srcElement.value = '';
          }
          fd.append('moduleName', typeFile);
          fd.append('file', this.userIncidentFileData.uploadedPhoto);
          this.spinnerService.show();
          this.commonService
            .callApi(
              this.callAPIConstants.incidentFileUpload,
              fd,
              'post',
              false,
              true
            )
            .then((success) => {
              this.spinnerService.hide();
              if (success.status === 1) {
                if (success.data.filePath.includes('images/')) {
                  var splitFilePath =
                    _.toString(_.split(success.data.filePath, 'images/')[1]) ||
                    'images/';
                } else {
                  var splitFilePath =
                    _.toString(_.split(success.data.filePath, 'videos/')[1]) ||
                    'videos/';
                }
                var filePath = {
                  filePath: splitFilePath,
                  fileType: success.data.fileType,
                };
                let data = {
                  type: success.data.fileType,
                  path: success.data.filePath,
                };
                if (typeFile === 'incident') {
                  this.userPhotoAndVideoData.push(data);
                  this.userIncidentFilePath.push(filePath);
                } else {
                  this.solutionImageAndVideos.push(data);
                  this.userSolutionFilePath.push(filePath);
                }
                let finalData = {
                  solutionTitle: this.addSolution.value.solutionTitle,
                  solutionDescription:
                    this.addSolution.value.solutionDescription,
                  solutionTags: this.addSolution.value.solutionTags,
                  solutionMedia: this.userSolutionFilePath,
                };
                if (this.inputData === 'Add Incident') {
                  this.solutionData.emit(finalData);
                }
              } else {
                this.showErrorService.popToast('error', success.message);
              }
            });
        };
        reader.readAsDataURL(input.files[0]);
        return true;
      } else if (!allowedExtensions.exec(event.target.value)) {
        this.showErrorService.popToast(
          'error',
          'Please select jpeg, jpg or png file.'
        );
        return false;
      }

      return true;
    }
  }

  /****************************************************************************
     @Purpose     : removeData
     @Parameter   : data, index, type
     @Return      : Na
  /****************************************************************************/
  removeData(data, index, type) {
    if (type === 'incident') {
      this.userPhotoAndVideoData.splice(index, 1);
      this.userIncidentFilePath.splice(index, 1);
    } else {
      this.solutionImageAndVideos.splice(index, 1);
      this.userSolutionFilePath.splice(index, 1);
    }
    let FileData = {
      filePath: data.path,
    };
    this.commonService
      .callApi(
        this.callAPIConstants.deleteMedia,
        FileData,
        'post',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          let finalData = {
            solutionTitle: this.addSolution.value.solutionTitle,
            solutionDescription: this.addSolution.value.solutionDescription,
            solutionTags: this.addSolution.value.solutionTags,
            solutionMedia: this.userSolutionFilePath,
          };
          if (this.inputData === 'Add Incident') {
            this.solutionData.emit(finalData);
          }
        }
      });
  }
}
