import {
  Component,
  Input,
  OnInit,
  Output,
  TemplateRef,
  EventEmitter,
} from '@angular/core';
import * as moment from 'moment';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { CommonService } from 'src/app/common/services/service/common.service';
import { GlobalSearchService } from 'src/app/common/services/service/global-search';
import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { faPlaneUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import { GlobalLanguageService } from 'src/app/common/services/service/global-language.service';

@Component({
  selector: 'app-side-incident-details',
  templateUrl: './side-incident-details.component.html',
  styles: [],
})
export class SideIncidentDetailsComponent implements OnInit {
  //variable declaration
  faPlane = faPlaneUp;
  faHealth = faPlus;
  public solutionRef: BsModalRef;
  @Input() incidentDetails: any;
  @Input() inputData: any;
  @Input() type: any;
  @Output() updatedData: any = new EventEmitter();
  checkclass: any;
  shakeLike: any;
  pulse: any;
  public isReadMore: boolean = true;
  public uploadedImage: any = this.localStorageService.defaultImage;
  public noImage: any = this.localStorageService.noImage;
  public incidentTitle = 'give solution';
  public incidentData: any;
  public callAPIConstants = callAPIConstants;
  public userComment: any;
  public isMycontribution;
  public reportList: Array<any> = [
    {
      name: 'Possible inappropriate content',
      id: 'Possible inappropriate content',
    },
    {
      name: 'Possible breach of confidentiality/anonymity',
      id: 'Possible breach of confidentiality/anonymity',
    },
  ];
  public shareIncidentData: any = {};
  public shareRef: BsModalRef;
  public modalRef: BsModalRef;
  public solutionData: any;
  public logedUserData: any = {};
  public incidentDetail: any = {
    type: 'myContribution',
  };
  public localLanguage: any;

  constructor(
    private modalService: BsModalService,
    private global: GlobalSearchService,
    public localStorageService: LocalStorageService,
    public commonService: CommonService,
    public showErrorService: ShowErrorService,
    public globalLanguageService: GlobalLanguageService
  ) {
    this.localLanguage = this.localStorageService.getToken('language');
    if (this.localLanguage == 'de') {
      this.reportList[0].name = 'Möglicherweise unangemessener Inhalt';
      this.reportList[1].name =
        'Mögliche Verletzung der Vertraulichkeit/Anonymität';
      this.incidentTitle = 'Lösung geben';
    }
    if (this.localLanguage == 'fr') {
      this.reportList[0].name = 'Contenu inapproprié possible';
      this.reportList[1].name =
        'Violation possible de la confidentialité/anonymat';
      this.incidentTitle = 'Proposer une solution';
    }
  }

  ngOnInit(): void {
    this.globalLanguageService.getGlobalLanguage().subscribe((value) => {
      this.localLanguage = value.data;
    });
    this.incidentData = this.incidentDetails;
    this.incidentData['seeOriginal'] = false;
    this.checkclass = this.incidentDetails.youLiked;
    this.global.setproperty.subscribe((res) => {
      this.checkclass = res.youLiked;
    });
    this.incidentData.createdAt = moment(this.incidentData.createdAt).fromNow();
    if (this.incidentData.isAsrs || this.incidentData.isTsb) {
      this.incidentData.postdate = moment(this.incidentData.postdate).fromNow();
    }
    if (this.type === 'solution') {
      this.incidentData.tags.map((data) => {
        data.name = '#' + data.name;
      });
    }

    this.isMycontribution = JSON.parse(
      this.localStorageService.getToken('isMycontribution')
    );
    if (this.isMycontribution) {
      this.inputData = '';
    }
  }

  navigateToExternalLink(link) {
    window.open(link, '_blank');
  }

  /****************************************************************************
          @Purpose     : Sharemodel
          @Parameter   : Na
          @Return      : Na
  /****************************************************************************/
  Sharemodel(template: TemplateRef<any>, data) {
    this.shareIncidentData = data;
    this.shareRef = this.modalService.show(template);
  }

  /****************************************************************************
            @Purpose     : submitReport
            @Parameter   : reportId, feedId
            @Return      : Na
  /****************************************************************************/
  submitReport(reportId, feedId) {
    if (reportId) {
      let data = {
        id: feedId,
        type: 'incident',
        reason: reportId,
      };
      this.commonService
        .callApi(
          this.callAPIConstants.ReportIncident,
          data,
          'post',
          false,
          false
        )
        .then((success) => {
          if (success.status === 1) {
            this.showErrorService.popToast('success', success.message);
            this.incidentData.reportedPending =
              !this.incidentData.reportedPending;
          }
        });
    }
  }
  /****************************************************************************
            @Purpose     : Solutionmodels
            @Parameter   : template
            @Return      : Na
  /****************************************************************************/
  Solutionmodels(template: TemplateRef<any>) {
    this.solutionRef = this.modalService.show(template);
  }
  /****************************************************************************
              @Purpose     : showText
              @Parameter   : Na
              @Return      : Na
  /****************************************************************************/
  showText() {
    this.isReadMore = !this.isReadMore;
  }
  /****************************************************************************
                @Purpose     : openModalWithClass
                @Parameter   : template,data,details
                @Return      : Na
  /****************************************************************************/
  openModalWithClass(template: TemplateRef<any>, data?, details?) {
    this.incidentDetail['data'] = data;
    this.incidentDetail['details'] = details;
    this.modalRef = this.modalService.show(
      template,
      Object.assign(
        {},
        {
          class: 'feed-model modal-lg',
          ignoreBackdropClick: true,
          keyboard: false,
        }
      )
    );
  }
  /****************************************************************************
                  @Purpose     : getSolutionData
                  @Parameter   : event
                  @Return      : Na
  /****************************************************************************/
  getSolutionData(event) {
    this.solutionData = event;
    let data = {
      title: this.solutionData.solutionTitle
        ? this.solutionData.solutionTitle
        : '',
      description: this.solutionData.solutionDescription
        ? this.solutionData.solutionDescription
        : '',
      tags: this.solutionData.solutionTags
        ? this.solutionData.solutionTags
        : '',
      parentIncident: this.incidentData._id,
      media: this.solutionData.solutionMedia
        ? this.solutionData.solutionMedia
        : '',
    };
    this.commonService
      .callApi(this.callAPIConstants.giveSolution, data, 'post')
      .then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          this.solutionRef.hide();
        }
      });
  }
  /****************************************************************************
                  @Purpose     : updateFeedsocialData
                  @Parameter   : incidentId, isAdd
                  @Return      : Na
  /****************************************************************************/
  updateFeedsocialData(incidentId: string, isAdd) {
    let data = {
      id: incidentId,
      ...(isAdd && { type: 'incident' }),
    };
    if (isAdd) {
      if (!this.incidentData.youLiked) {
        this.shakeLike = true;
        setTimeout(() => {
          this.shakeLike = false;
        }, 1500);
      }
      this.incidentData.likes = this.incidentData.youLiked
        ? this.incidentData.likes - 1
        : this.incidentData.likes + 1;
      this.incidentData.youLiked = !this.incidentData.youLiked;
      this.checkclass = this.incidentData.youLiked;
    } else {
      if (!this.incidentData.isFavorite) {
        this.pulse = true;
        setTimeout(() => {
          this.pulse = false;
        }, 1000);
      }
      this.incidentData.isFavorite = !this.incidentData.isFavorite;
    }
    const API = isAdd
      ? this.callAPIConstants.likeAndIncident
      : this.callAPIConstants.addFavourite;
    this.commonService
      .callApi(API, data, 'post', false, false)
      .then((success) => {
        if (success.status === 1) {
          // this.showErrorService.popToast('success', success.message);
          // if (success.data) {
          //   this.incidentData.likes = success.data.likes;
          //   this.updatedData.emit(true);
          // }
          // if (!isAdd) {
          //   this.incidentData.isFavorite = !this.incidentData.isFavorite;
          // }
        } else {
          this.showErrorService.popToast('error', success.message);
          if (isAdd) {
            this.incidentData.likes = this.incidentData.youLiked
              ? this.incidentData.likes - 1
              : this.incidentData.likes + 1;
            this.incidentData.youLiked = !this.incidentData.youLiked;
            this.checkclass = this.incidentData.youLiked;
          } else {
            this.incidentData.isFavorite = !this.incidentData.isFavorite;
          }
        }
      });
  }
  /****************************************************************************
                  @Purpose     : addComment
                  @Parameter   : id
                  @Return      : Na
  /****************************************************************************/
  addComment(id) {
    let data = {
      postId: id,
      comment: this.userComment,
    };
    this.commonService
      .callApi(this.callAPIConstants.addComment, data, 'post', false, false)
      .then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          this.incidentData.commentCount += 1;
          this.userComment = null;
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
  }

  /****************************************************************************
           @Purpose     : removeReport
           @Parameter   : reportId, feedId
           @Return      : Na
 /****************************************************************************/
  removeReport(feedId, type) {
    let data = {
      id: feedId,
      type: type,
    };
    this.commonService
      .callApi(
        this.callAPIConstants.RemoveReportIncident,
        data,
        'post',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          this.incidentData.reportedPending = false;
        }
      });
  }

  ngDoCheck() {
    if (this.localStorageService.getToken('accessToken')) {
      this.logedUserData = JSON.parse(
        this.localStorageService.getToken('currentUser')
      );
    }
  }

  ngOnDestroy(): void {
    if (this.isMycontribution)
      this.localStorageService.removeToken('isMycontribution');

    //this.global.setproperty.unsubscribe();
  }
}
