import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { SlidesOutputData, OwlOptions } from 'ngx-owl-carousel-o';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LocalStorageService } from '../../../common/services/service/local-storage.service';
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { CommonService } from 'src/app/common/services/service/common.service';
import { ShowErrorService } from 'src/app/common/services/service/show-error.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-view-media',
  templateUrl: './view-media.component.html',
  styles: [],
})
export class ViewMediaComponent implements OnInit {
  // Variable Declaration
  public CommnetDetail: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoWidth: false,
    navSpeed: 700,
    items: 1,
    navText: ['<i class="icon-left"></i>', '<i class="icon-right"></i>'],
    nav: true,
  };
  public data: any;
  public uploadedImage: any = this.localStorageService.defaultImage;
  public callAPIConstants = callAPIConstants;
  public shareRef: BsModalRef;
  public shareIncidentData: any = {};
  public isReadMore: boolean = true;
  @Input() modal: any;
  @Input() modalData: any;
  public noImage: any = this.localStorageService.noImage;
  shakeLike: boolean;
  pulse: boolean;
  public logedUserData: any = {};

  constructor(
    public localStorageService: LocalStorageService,
    private modalService: BsModalService,
    public commonService: CommonService,
    public showErrorService: ShowErrorService,
    public route: Router
  ) {}

  ngOnInit(): void {
    this.logedUserData = JSON.parse(
      this.localStorageService.getToken('currentUser')
    );
  }
  /****************************************************************************
                    @Purpose     : startVidPlaying
                    @Parameter   : videoIndex
                    @Return      : Na
  /****************************************************************************/
  startVidPlaying(videoIndex) {
    let videos: any = document.querySelectorAll('video');
    videos.forEach((element) => {
      if (element.id !== 'mediaVideo' + videoIndex) {
        if (
          !!(
            element.currentTime > 0 &&
            !element.paused &&
            !element.ended &&
            element.readyState > 2
          )
        ) {
          element.pause();
        }
      }
    });
  }

  /****************************************************************************
                      @Purpose     : showText
                      @Parameter   : videoIndex
                      @Return      : Na
  /****************************************************************************/
  showText() {
    this.isReadMore = !this.isReadMore;
  }
  /****************************************************************************
                        @Purpose     : Sharemodel
                        @Parameter   : template, data
                        @Return      : Na
  /****************************************************************************/
  Sharemodel(template: TemplateRef<any>, data) {
    this.shareIncidentData = data;
    this.shareRef = this.modalService.show(template);
  }

  /****************************************************************************
                        @Purpose     : redirectToComment
                        @Parameter   : id
                        @Return      : Na
  /****************************************************************************/
  redirectToComment(id) {
    this.route.navigate(['/comments/' + id]);
    this.modal.hide();
  }
  /****************************************************************************
                          @Purpose     : updateFeedsocialData
                          @Parameter   : incidentId, isAdd
                          @Return      : Na
  /****************************************************************************/
  updateFeedsocialData(incidentId: string, isAdd) {
    let data = {
      id: incidentId,
      ...(isAdd && { type: 'incident' }),
    };
    if (isAdd) {
      if (!this.modalData.details.youLiked) {
        this.shakeLike = true;
        setTimeout(() => {
          this.shakeLike = false;
        }, 1500);
      }
      this.modalData.details.likes = this.modalData.details.youLiked
        ? this.modalData.details.likes - 1
        : this.modalData.details.likes + 1;
      this.modalData.details.youLiked = !this.modalData.details.youLiked;
    } else {
      if (!this.modalData.details.isFavorite) {
        this.pulse = true;
        setTimeout(() => {
          this.pulse = false;
        }, 1000);
      }
      this.modalData.details.isFavorite = !this.modalData.details.isFavorite;
    }
    const API = isAdd
      ? this.callAPIConstants.likeAndIncident
      : this.callAPIConstants.addFavourite;
    this.commonService
      .callApi(API, data, 'post', false, false)
      .then((success) => {
        if (success.status === 1) {
          // if (success.data) {
          //   this.modalData.details.likes = success.data.likes;
          // }
          // if (!isAdd) {
          //   this.modalData.details.isFavorite =
          //     !this.modalData.details.isFavorite;
          // }
        } else {
          this.showErrorService.popToast('error', success.message);
          if (isAdd) {
            this.modalData.details.likes = this.modalData.details.youLiked
              ? this.modalData.details.likes - 1
              : this.modalData.details.likes + 1;
            this.modalData.details.youLiked = !this.modalData.details.youLiked;
          } else {
            this.modalData.details.isFavorite =
              !this.modalData.details.isFavorite;
          }
        }
      });
  }
}
