import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/map';
import { messages } from '../../constants/errorMessagesData';
import { messagesDe } from '../../constants/errorMessagesGermanData';
import { messagesFr } from '../../constants/errorMessagesFrenchData';
import { LocalStorageService } from '../service/local-storage.service';

@Injectable()
export class ErrorMessages {
  public MSG = messages as any;
  public gMSG = messagesDe as any;
  public frMSG = messagesFr as any;

  constructor(public localStorageService: LocalStorageService) {}

  /*****************************************************************************************
  @PURPOSE      : To Show Error on Form validations
  @PARAMETERS   : field, error
  @RETURN       : message
  /*****************************************************************************************/
  getError(field, error) {
    let message = '';
    const currentLanguage = this.localStorageService.getToken('language');
    let messagesObj;
    if (currentLanguage === 'de') {
      messagesObj = this.gMSG;
    } else if (currentLanguage === 'fr') {
      messagesObj = this.frMSG;
    } else {
      messagesObj = this.MSG;
    }
    if (error) {
      if (error.required) {
        const required = messagesObj.ERROR.REQUIRED;
        switch (field) {
          case 'fullName':
            {
              message = required.fullName;
            }
            break;
          case 'email':
            {
              message = required.email;
            }
            break;
          case 'password':
            {
              message = required.password;
            }
            break;
          case 'confirmPassword':
            {
              message = required.confirmPassword;
            }
            break;
          case 'agreeTermsAndConditions':
            {
              message = required.agreeTermsAndConditions;
            }
            break;
          case 'emailOrLoginId':
            {
              message = required.emailOrLoginId;
            }
            break;
          case 'forgetEmail':
            {
              message = required.forgetEmail;
            }
            break;
          case 'userRecommendDomain':
            {
              message = required.userRecommendDomain;
            }
            break;
          case 'hospital':
            {
              message = required.hospital;
            }
            break;
          case 'departmentOfHealth':
            {
              message = required.departmentOfHealth;
            }
            break;
          case 'designation':
            {
              message = required.designation;
            }
            break;
          case 'role':
            {
              message = required.role;
            }
            break;
          case 'yearOfExp':
            {
              message = required.yearOfExp;
            }
            break;
          case 'DOB':
            {
              message = required.DOB;
            }
            break;
          case 'personalEmail':
            {
              message = required.personalEmail;
            }
            break;
          case 'contactNumber':
            {
              message = required.contactNumber;
            }
            break;
          case 'oldPassword':
            {
              message = required.oldPassword;
            }
            break;
          case 'newPassword':
            {
              message = required.newPassword;
            }
            break;
          case 'confirmNewPassword':
            {
              message = required.confirmNewPassword;
            }
            break;
          case 'domainEmail':
            {
              message = required.domainEmail;
            }
            break;
          case 'selectDomain':
            {
              message = required.selectDomain;
            }
            break;
          case 'healthcareRegion':
            {
              message = required.healthcareRegion;
            }
            break;
          case 'healthcareSubRegion':
            {
              message = required.healthcareSubRegion;
            }
            break;
          case 'healthcareRegionCanada':
            {
              message = required.healthcareRegionCanada;
            }
            break;
          case 'region':
            {
              message = required.region;
            }
            break;
          case 'provinceCanada':
            {
              message = required.provinceCanada;
            }
            break;
          case 'placeOfService':
            {
              message = required.placeOfService;
            }
            break;
          case 'airline':
            {
              message = required.airline;
            }
            break;
          case 'departmentOfHealthCare':
            {
              message = required.departmentOfHealthCare;
            }
            break;
          case 'incidentType':
            {
              message = required.incidentType;
            }
            break;
          case 'incidentTitle':
            {
              message = required.incidentTitle;
            }
            break;
          case 'incidentDescription':
            {
              message = required.incidentDescription;
            }
            break;
          case 'incidentReason':
            {
              message = required.incidentReason;
            }
            break;
          case 'deleteDescription':
            {
              message = required.deleteDescription;
            }
            break;
          case 'solutionTitle':
            {
              message = required.solutionTitle;
            }
            break;
          case 'name':
            {
              message = required.name;
            }
            break;
          case 'message':
            {
              message = required.message;
            }
            break;
          case 'type':
            {
              message = required.type;
            }
            break;
          case 'expertise':
            {
              message = required.expertise;
            }
            break;
        }
      } else if (error.pattern) {
        const pattern = this.MSG.ERROR.PATTERN;
        switch (field) {
          case 'email':
            {
              message = pattern.email;
            }
            break;
          case 'personalEmail':
            {
              message = pattern.personalEmail;
            }
            break;
          case 'password':
            {
              message = pattern.password;
            }
            break;
          case 'newPassword':
            {
              message = pattern.newPassword;
            }
            break;
          case 'userRecommendDomain':
            {
              message = pattern.userRecommendDomain;
            }
            break;
        }
      } else if (error.minlength) {
        const minlength = this.MSG.ERROR.MINLENGTH;
        switch (field) {
          case 'mobile':
            {
              message = minlength.mobile;
            }
            break;
        }
      }
      return message;
    }
  }
}
