<div [ngClass]="showIndustrySelection ? 'onboarding-bg' : ''">
  <div class="p-5" [hidden]="!showInitialModal" style="width: 100%">
    <a
      *ngIf="!showIndustrySelection && globalSignup"
      style="position: relative; top: 2px"
      class="d-block text-pink font-semibold"
      href="javascript:void(0)"
      (click)="showIndustrySelection = true"
      >{{ 'Back' | translate }}</a
    >
    <div class="onboarding-modal-start">
      <button
        (click)="showGetSetup ? closeModal(false) : closeModal(true)"
        type="button"
        class="close pull-right"
        aria-label="Close"
        [hidden]="showGetSetup"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <div [hidden]="!showSetPassword">
      <div
        *ngIf="showIndustrySelection"
        class="row"
        style="transition: visibility 0.5s"
      >
        <!-- <div
          class="col-6"
          style="display: flex; justify-content: center; align-items: center"
        >
          <div
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <img src="assets/images/learngraphic.png" alt="Laddy Image" />
            <div class="btn-box text-center mt-4 mt-md-5">
              <button
                (click)="industrySelection('aviation')"
                type="submit"
                class="large-btn btn-primary"
              >
                Aviation
              </button>
            </div>
          </div>
        </div>
        <div
          class="col-6"
          style="display: flex; justify-content: center; align-items: center"
        >
          <div
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <img src="assets/images/learngraphic.png" alt="Laddy Image" />

            <div class="btn-box text-center mt-4 mt-md-5">
              <button
                (click)="industrySelection('healthcare')"
                type="submit"
                class="large-btn btn-primary"
              >
                Healthcare
              </button>
            </div>
          </div>
        </div> -->
        <div class="col-12">
          <div class="row justify-content-center">
            <div
              style="
                font-weight: bold;
                font-size: 25px;
                letter-spacing: 0px;
                color: #373535;
                opacity: 1;
              "
              class="text-center"
              [ngClass]="hideImage ? 'onboarding-heading-paddig' : 'mx-1'"
            >
              {{
                'This application is designed specifically for aviation and
              healthcare professionals. Please select your profession from the
              options below.' | translate
              }}
            </div>
          </div>
          <div
            class="row justify-content-center"
            [ngClass]="hideImage ? 'onboarding-industry-padding' : 'mt-4'"
          >
            <div
              (click)="industrySelection('aviation')"
              [ngClass]="hideImage ? 'col-5' : 'col-12'"
              class="industry-card d-flex align-items-center justify-content-center"
            >
              <img
                height="71"
                width="71"
                src="assets/images/plane.png"
                alt="Laddy Image"
              />
              <div class="d-flex flex-column ml-4" style="width: 200px">
                <div
                  style="
                    font-size: 26px/35px;
                    font-weight: bold;
                    letter-spacing: 0px;
                    color: #00aeef;
                    opacity: 1;
                  "
                >
                  {{ 'Aviation' | translate }}
                </div>
                <div style="color: #00aeef">
                  {{ 'Aviation professional' | translate }}
                </div>
              </div>
            </div>
            <div *ngIf="hideImage" style="width: 50px"></div>
            <div
              (click)="industrySelection('healthcare')"
              [ngClass]="hideImage ? 'col-5 ' : 'col-12 mt-3'"
              class="industry-card d-flex align-items-center justify-content-center"
            >
              <img
                height="71"
                width="71"
                src="assets/images/stethoscope.png"
                alt="Laddy Image"
              />
              <div class="d-flex flex-column ml-4" style="width: 200px">
                <div
                  style="
                    font-size: 26px/35px;
                    font-weight: bold;
                    letter-spacing: 0px;
                    color: #00aeef;
                    opacity: 1;
                  "
                >
                  {{ 'Healthcare' | translate }}
                </div>
                <div style="color: #00aeef">
                  {{ 'Healthcare professional' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row dhjshjdhjahsjs"
        *ngIf="!showIndustrySelection"
        style="transition: visibility 0.5s"
      >
        <div
          class="col-6"
          style="display: flex; justify-content: center; align-items: center"
          *ngIf="hideImage"
        >
          <img
            *ngIf="selectedIndustry === 'healthcare'"
            src="assets/images/learngraphic.png"
            alt="Laddy Image"
          />
          <img
            *ngIf="selectedIndustry === 'aviation'"
            src="assets/images/countryaviation.png"
            alt="Laddy Image"
          />
          <img
            *ngIf="selectedIndustry === ''"
            src="assets/images/setpasswordgraphic.png"
            alt="Laddy Image"
          />
        </div>
        <div
          [ngClass]="hideImage ? 'col-6' : 'col-12'"
          style="
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            justify-items: center;
            align-content: center;
          "
        >
          <div class="w-100">
            <h2 class="text-center form-title">
              {{
                globalSignup
                  ? ('Create your Account' | translate)
                  : ('Set Password For Your Account' | translate)
              }}
            </h2>
            <form
              *ngIf="!globalSignup"
              [formGroup]="resetForm"
              class="px-lg-5 px-xl-5"
              (ngSubmit)="resetFormSubmit()"
            >
              <div class="form-group input-icon">
                <label class="sr-only" for="password">
                  {{ 'Password' | translate }}</label
                >
                <span class="ic-icon icon-password"></span>
                <input
                  class="form-control"
                  placeholder="{{ 'Password' | translate }}"
                  type="{{ passstatus ? 'text' : 'password' }}"
                  id="password"
                  formControlName="password"
                />
                <span
                  (click)="passwordEvent('password')"
                  [ngClass]="passstatus ? 'icon-passshow' : 'icon-passhide'"
                  class="pass-icon"
                ></span>
                <span
                  class="error-msg"
                  *ngIf="
                    (submitted || resetForm.controls.password.touched) &&
                    resetForm.controls.password.invalid
                  "
                >
                  {{
                    showErrorService.showError(
                      'password',
                      resetForm.controls.password.errors
                    )
                  }}
                </span>
              </div>
              <div class="form-group input-icon">
                <label class="sr-only" for="confirmPassword">{{
                  'Confirm password' | translate
                }}</label>
                <span class="ic-icon icon-password"></span>
                <input
                  class="form-control"
                  placeholder="{{ 'Confirm password' | translate }}"
                  type="{{ confirmPasswordStatus ? 'text' : 'password' }}"
                  id="confirmPassword"
                  formControlName="confirmPassword"
                />
                <span
                  (click)="passwordEvent('confirmPassword')"
                  [ngClass]="
                    confirmPasswordStatus ? 'icon-passshow' : 'icon-passhide'
                  "
                  class="pass-icon"
                ></span>
                <span
                  class="error-msg"
                  *ngIf="
                    (submitted || resetForm.controls.confirmPassword.touched) &&
                    resetForm.controls.confirmPassword.invalid
                  "
                >
                  {{
                    showErrorService.showError(
                      'confirmPassword',
                      resetForm.controls.confirmPassword.errors
                    )
                  }}
                </span>
                <span
                  class="error-msg"
                  *ngIf="
                    resetForm.errors?.mismatch &&
                    (resetForm.controls['password'].dirty ||
                      resetForm.controls['confirmPassword'].touched)
                  "
                >
                  {{ 'Passwords do not match.' | translate }}
                </span>
              </div>
              <div class="btn-box text-center mt-4 mt-md-5">
                <button type="submit" class="large-btn btn-primary">
                  {{ 'Submit' | translate }}
                </button>
              </div>
            </form>
            <form
              *ngIf="globalSignup"
              [formGroup]="signupForm"
              class="px-lg-5 px-xl-5"
              (ngSubmit)="registerFormSubmit()"
            >
              <div
                style="margin-bottom: 7px !important"
                class="form-group input-icon"
              >
                <label class="sr-only" for="email">Email</label>
                <span class="ic-icon icon-email"></span>
                <input
                  class="form-control"
                  placeholder="{{ 'Email' | translate }}"
                  type="text"
                  id="email"
                  autocomplete="off"
                  formControlName="email"
                  (keydown)="restrictKeyPressService.RestrictSpace($event)"
                />
                <div style="display: flex; flex-direction: column">
                  <small class="text-muted mx-2 mt-1">{{
                    ('Please provide a valid ' | translate) +
                      (selectedIndustry === 'aviation'
                        ? (' aviation ' | translate)
                        : (' healthcare ' | translate)) +
                      (' email address which will be used for verification purposes only. It will not be visible to other users or Dicerra staff.'
                        | translate)
                  }}</small>
                </div>
                <span
                  class="error-msg"
                  *ngIf="
                    (submitted || signupForm.controls.email.touched) &&
                    signupForm.controls.email.invalid
                  "
                >
                  {{
                    showErrorService.showError(
                      'email',
                      signupForm.controls.email.errors
                    )
                  }}
                </span>
              </div>
              <div class="form-group input-icon">
                <label class="sr-only" for="password">Password</label>
                <span class="ic-icon icon-password"></span>
                <input
                  class="form-control"
                  placeholder="{{ 'Password' | translate }}"
                  type="{{ passstatus ? 'text' : 'password' }}"
                  id="password"
                  formControlName="password"
                />
                <span
                  (click)="passwordEvent('password')"
                  [ngClass]="passstatus ? 'icon-passshow' : 'icon-passhide'"
                  class="pass-icon"
                ></span>
                <span
                  class="error-msg"
                  *ngIf="
                    (submitted || signupForm.controls.password.touched) &&
                    signupForm.controls.password.invalid
                  "
                >
                  {{
                    showErrorService.showError(
                      'password',
                      signupForm.controls.password.errors
                    )
                  }}
                </span>
              </div>
              <div class="form-group input-icon">
                <label class="sr-only" for="confirmPassword"
                  >Confirm Password</label
                >
                <span class="ic-icon icon-password"></span>
                <input
                  class="form-control"
                  placeholder="{{ 'Confirm password' | translate }}"
                  type="{{ confirmPasswordStatus ? 'text' : 'password' }}"
                  id="confirmPassword"
                  formControlName="confirmPassword"
                />
                <span
                  (click)="passwordEvent('confirmPassword')"
                  [ngClass]="
                    confirmPasswordStatus ? 'icon-passshow' : 'icon-passhide'
                  "
                  class="pass-icon"
                ></span>
                <span
                  class="error-msg"
                  *ngIf="
                    (submitted ||
                      signupForm.controls.confirmPassword.touched) &&
                    signupForm.controls.confirmPassword.invalid
                  "
                >
                  {{
                    showErrorService.showError(
                      'confirmPassword',
                      signupForm.controls.confirmPassword.errors
                    )
                  }}
                </span>
                <span
                  class="error-msg"
                  *ngIf="
                    signupForm.errors?.mismatch &&
                    (signupForm.controls['password'].dirty ||
                      signupForm.controls['confirmPassword'].touched)
                  "
                >
                  {{ 'Passwords do not match.' | translate }}
                </span>
              </div>
              <div class="custom-control custom-checkbox my-4">
                <input
                  type="checkbox"
                  class="ccheck custom-control-input"
                  id="agreeTermsAndConditions"
                  formControlName="agreeTermsAndConditions"
                />
                <label
                  class="custom-control-label"
                  for="agreeTermsAndConditions"
                ></label>

                <span
                  >{{ 'By signing up, you agree to our' | translate }}
                  <a
                    style="color: #3bade2"
                    href="javascript:void(0)"
                    class="forgotpass"
                    (click)="openTermsAndConditions(template2)"
                    >{{ 'Terms & Conditions' | translate }}</a
                  >, {{ 'and' | translate }}
                  <a
                    style="color: #3bade2"
                    href="javascript:void(0)"
                    class="forgotpass"
                    (click)="openPrivacyPolicy(template1)"
                    >{{ 'Privacy Policy' | translate }}.</a
                  ></span
                >
                <span
                  class="error-msg"
                  *ngIf="
                    (submitted ||
                      signupForm.controls.agreeTermsAndConditions.touched) &&
                    signupForm.controls.agreeTermsAndConditions.invalid
                  "
                >
                  {{ 'Select Checkbox' | translate }}
                </span>
              </div>
              <!-- <small
                >Your email address is used for account verification purposes
                only and will not be visible to other users or Dicerra
                staff</small
              > -->
              <div class="btn-box text-center mt-3">
                <button type="submit" class="large-btn btn-primary">
                  {{ 'Submit' | translate }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div [hidden]="!showGetSetup">
      <div class="row" style="transition: visibility 0.5s">
        <div class="col-md-12">
          <div class="d-flex justify-content-end">
            <ng-select
              [searchable]="false"
              [clearable]="false"
              [items]="languageArr"
              class="custom-slectmenu"
              bindLabel="name"
              bindValue="value"
              placeholder="{{ 'Select Language' | translate }}"
              [(ngModel)]="selectedLanguage"
              (change)="onLanguageSelection($event)"
              [ngModelOptions]="{ standalone: true }"
              [ngClass]="hideImage ? ' ' : 'col-12 pb-4'"
            >
            </ng-select>
          </div>
        </div>
        <div class="col-6" *ngIf="hideImage">
          <img src="assets/images/setpasswordgraphic.png" alt="Laddy Image" />
        </div>
        <div
          [ngClass]="hideImage ? 'col-6' : 'col-12'"
          style="
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            justify-items: center;
            align-content: center;
          "
        >
          <div class="w-100">
            <h2 class="text-center form-title">
              {{ 'Lets get you set up' | translate }}
            </h2>
            <div class="text-center">
              {{
                "It only takes a moment and it'll make your experience with Dicerra even better."
                  | translate
              }}
            </div>
            <div class="btn-box text-center mt-4 mt-md-5">
              <button
                (click)="startSetup()"
                type="submit"
                class="large-btn btn-primary"
              >
                {{ 'Get set up' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [hidden]="showInitialModal">
    <div
      class="p-5"
      *ngIf="showingElement !== 0 && userIndustry === 'healthcare'"
      style="width: 100%"
    >
      <div
        [ngClass]="
          showingElement !== 1 && !onboardingCompleted
            ? 'onboarding-modal-start'
            : 'onboarding-modal-end'
        "
      >
        <a
          class="d-block text-pink font-semibold"
          href="javascript:void(0)"
          (click)="navigateBack(showingElement - 1)"
          *ngIf="showingElement !== 1 && !onboardingCompleted"
          >{{ 'Back' | translate }}</a
        >
        <button
          (click)="onboardingCompleted ? closeModal(true) : closeModal(false)"
          type="button"
          class="close pull-right"
          aria-label="Close"
          *ngIf="showingElement === 5 || onboardingCompleted"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div>
        <div
          style="transition: visibility 0.5s"
          *ngIf="!onboardingCompleted"
          class="mb-5"
        >
          <progressbar [max]="5" [value]="value" [animate]="true"></progressbar>
          <div style="float: right">
            <span>{{ value + '/5' }}</span>
          </div>
        </div>

        <div
          class="row"
          style="transition: visibility 0.5s"
          [hidden]="showingElement !== 1"
        >
          <div class="col-6" *ngIf="hideImage">
            <img src="assets/images/issuegraphic.png" alt="Laddy Image" />
          </div>
          <div
            [ngClass]="hideImage ? 'col-6' : 'col-12'"
            style="position: relative"
          >
            <div [ngClass]="hideImage ? 'mt-5 pt-5' : ''">
              <div>{{ 'Please select your country' | translate }}</div>
              <form [formGroup]="userCountryForm">
                <div class="form-group mb-md-4">
                  <!-- <label class="label" for="country">Country</label> -->
                  <div class="mt-3">
                    <ng-select
                      class="custom-slectmenu"
                      [items]="countriesArr"
                      bindLabel="name"
                      bindValue="name"
                      placeholder="{{ 'Country' | translate }}"
                      formControlName="country"
                      (change)="setValueForSelect('country', $event)"
                      (clear)="resetSelection('country')"
                      required
                    >
                    </ng-select>
                    <span
                      class="error-msg"
                      *ngIf="
                        (countrySubmitted ||
                          userCountryForm.controls.country.touched) &&
                        userCountryForm.controls.country.invalid
                      "
                    >
                      {{ ' Select Country' | translate }}
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="row" *ngIf="hideImage">
              <div class="col-4"></div>
              <div
                class="btn-block d-flex flex-md-row flex-column justify-content-center col-8"
                style="position: absolute; bottom: 0; right: 0"
              >
                <!-- <a
                  style="text-decoration: underline; color: #dc3163"
                  class="mt-1 mr-3"
                  href="javascript:void(0)"
                  (click)="closeModal(true)"
                  >Remind me later</a
                > -->
                <button
                  (click)="navigate(2)"
                  class="large-btn btn-primary ml-md-2 w-60"
                >
                  {{ 'Next' | translate }}
                </button>
              </div>
            </div>
            <div
              *ngIf="!hideImage"
              class="btn-block d-flex flex-column justify-content-center"
            >
              <!-- <a
                style="
                  text-decoration: underline;
                  color: #dc3163;
                  text-align: center;
                "
                class="mt-3"
                href="javascript:void(0)"
                (click)="closeModal(true)"
                >Remind me later</a
              > -->
              <button
                (click)="navigate(2)"
                class="large-btn btn-primary mt-3 w-100"
              >
                {{ 'Next' | translate }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="row"
          style="transition: visibility 0.5s"
          [hidden]="showingElement !== 2"
        >
          <div class="col-6" *ngIf="hideImage">
            <img src="assets/images/sharebeyondgraphic.png" alt="Laddy Image" />
          </div>
          <div
            [ngClass]="hideImage ? 'col-6' : 'col-12'"
            style="position: relative"
          >
            <div [ngClass]="hideImage ? 'mt-5 pt-5' : ''">
              <div>
                {{
                  selectedCountry === 'Canada'
                    ? ('Please select your province and healthcare region'
                      | translate)
                    : ('Please select your region and sub-region' | translate)
                }}
              </div>
              <form [formGroup]="userRegionsForm">
                <div class="form-group mb-md-4">
                  <!-- <label class="label" for="country">Country</label> -->
                  <div class="mt-3">
                    <ng-select
                      class="custom-slectmenu"
                      [items]="regionsArr"
                      bindLabel="name"
                      bindValue="name"
                      placeholder="{{
                        selectedCountry === 'Canada'
                          ? ('Province' | translate)
                          : ('Healthcare Region' | translate)
                      }}"
                      formControlName="region"
                      required
                      (change)="setValueForSelect('region', $event)"
                    >
                    </ng-select>
                    <span
                      class="error-msg"
                      *ngIf="
                        (regionsSubmitted ||
                          userRegionsForm.controls.region.touched) &&
                        userRegionsForm.controls.region.invalid
                      "
                    >
                      {{
                        selectedCountry === 'Canada'
                          ? ('Select Province' | translate)
                          : ('Select Region' | translate)
                      }}
                    </span>
                  </div>
                </div>
                <div class="form-group mb-md-4">
                  <!-- <label class="label" for="country">Country</label> -->
                  <div class="mt-3">
                    <ng-select
                      class="custom-slectmenu"
                      [items]="subregionsArr"
                      bindLabel="name"
                      bindValue="name"
                      placeholder=" {{
                        countriesSelected.includes(selectedCountry)
                          ? ('Healthcare Region' | translate)
                          : ('Sub-Region' | translate)
                      }}"
                      formControlName="subRegion"
                      required
                    >
                    </ng-select>
                    <span
                      class="error-msg"
                      *ngIf="
                        (regionsSubmitted ||
                          userRegionsForm.controls.subRegion.touched) &&
                        userRegionsForm.controls.subRegion.invalid
                      "
                    >
                      {{
                        countriesSelected.includes(selectedCountry)
                          ? ('Select Healthcare Region' | translate)
                          : ('Select Sub-Region' | translate)
                      }}
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="row" *ngIf="hideImage">
              <div class="col-4"></div>
              <div
                class="btn-block d-flex flex-md-row flex-column justify-content-center col-8"
                style="position: absolute; bottom: 0; right: 0"
              >
                <!-- <a
                  style="text-decoration: underline; color: #dc3163"
                  class="mt-1 mr-3"
                  href="javascript:void(0)"
                  (click)="closeModal(true)"
                  >Remind me later</a
                > -->
                <button
                  (click)="navigate(3)"
                  class="large-btn btn-primary ml-md-2 w-60"
                >
                  {{ 'Next' | translate }}
                </button>
              </div>
            </div>
            <div
              *ngIf="!hideImage"
              class="btn-block d-flex flex-column justify-content-center"
            >
              <!-- <a
                style="
                  text-decoration: underline;
                  color: #dc3163;
                  text-align: center;
                "
                class="mt-3"
                href="javascript:void(0)"
                (click)="closeModal(true)"
                >Remind me later</a
              > -->
              <button
                (click)="navigate(3)"
                class="large-btn btn-primary mt-3 w-100"
              >
                {{ 'Next' | translate }}
              </button>
            </div>
          </div>
        </div>

        <div
          class="row"
          style="transition: visibility 0.5s"
          [hidden]="showingElement !== 3"
        >
          <div class="col-6 d-flex justify-content-center" *ngIf="hideImage">
            <img
              src="assets/images/focusgraphic.png"
              style="height: 470px !important"
              alt="Laddy Image"
            />
          </div>
          <div
            [ngClass]="hideImage ? 'col-6' : 'col-12'"
            style="position: relative; transition: ease-in 1s"
          >
            <div [ngClass]="hideImage ? 'mt-3 pt-2' : ''">
              <form
                [formGroup]="userDepartmentForm"
                style="padding-bottom: 30px"
              >
                <div
                  *ngIf="countriesSelected.includes(selectedCountry)"
                  class="row no-gutters"
                >
                  <div
                    [ngClass]="
                      userDepartmentForm.value.designation ===
                      'Other Designation'
                        ? 'col-7'
                        : 'col'
                    "
                  >
                    <div class="form-group">
                      <label class="label" for="department">{{
                        'Please select your designation' | translate
                      }}</label>
                      <ng-select
                        class="custom-slectmenu"
                        [items]="designationArr"
                        bindLabel="name"
                        bindValue="name"
                        placeholder="{{ 'Designation' | translate }}"
                        formControlName="designation"
                        (change)="setValueForSelect('designation', $event)"
                        (clear)="resetSelection('designation')"
                        #designation
                      >
                      </ng-select>
                      <span
                        class="error-msg"
                        *ngIf="
                          departmentSubmitted &&
                          userDepartmentForm.controls.designation.invalid
                        "
                      >
                        {{
                          showErrorService.showError(
                            'designation',
                            userDepartmentForm.controls.designation.errors
                          )
                        }}
                      </span>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      userDepartmentForm.value.designation ===
                      'Other Designation'
                    "
                    class="col-5 d-flex"
                  >
                    <div class="form-group mb-md-4 w-100 pl-2 pt-4 mt-2">
                      <label class="label sr-only" for="designation">{{
                        'Add Other Designation' | translate
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="designation"
                        placeholder="{{ 'Add Other Designation' | translate }}"
                        formControlName="otherDesignation"
                      />
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="
                    countriesSelected.includes(selectedCountry) &&
                    positionConditionArray.includes(selectedDesignation)
                  "
                  class="row no-gutters"
                >
                  <div
                    [ngClass]="
                      userDepartmentForm.value.position == 'Other Position'
                        ? 'col-7'
                        : 'col'
                    "
                  >
                    <div class="form-group">
                      <label class="label" for="department">{{
                        'Please select your position' | translate
                      }}</label>
                      <ng-select
                        class="custom-slectmenu"
                        [items]="positionArr"
                        bindLabel="name"
                        bindValue="name"
                        placeholder="{{ 'Position' | translate }}"
                        formControlName="position"
                        (change)="setValueForSelect('position', $event)"
                        (clear)="resetSelection('position')"
                        #position
                      >
                      </ng-select>
                      <span
                        class="error-msg"
                        *ngIf="
                          departmentSubmitted &&
                          userDepartmentForm.controls.position.invalid
                        "
                      >
                        {{
                          showErrorService.showError(
                            'designation',
                            userDepartmentForm.controls.position.errors
                          )
                        }}
                      </span>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      userDepartmentForm.value.position == 'Other Position'
                    "
                    class="col-5 d-flex"
                  >
                    <div class="form-group mb-md-4 w-100 pl-2 pt-4 mt-2">
                      <label class="label sr-only" for="designation">{{
                        'Add Other Position' | translate
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="designation"
                        placeholder="{{ 'Add Other Position' | translate }}"
                        formControlName="otherPosition"
                      />
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="countriesSelected.includes(selectedCountry)"
                  class="row no-gutters"
                >
                  <div
                    [ngClass]="
                      userDepartmentForm.value.role === 'Other Role'
                        ? 'col-7'
                        : 'col'
                    "
                  >
                    <div class="form-group">
                      <label class="label" for="role">{{
                        'Please select your role' | translate
                      }}</label>
                      <ng-select
                        class="custom-slectmenu"
                        [items]="rolesArr"
                        bindLabel="name"
                        bindValue="name"
                        placeholder="{{ 'Role' | translate }}"
                        formControlName="role"
                        (clear)="resetSelection('role')"
                        (change)="setValueForSelect('role', $event)"
                      >
                      </ng-select>
                      <span
                        class="error-msg"
                        *ngIf="
                          departmentSubmitted &&
                          userDepartmentForm.controls.role.invalid
                        "
                      >
                        {{
                          showErrorService.showError(
                            'role',
                            userDepartmentForm.controls.role.errors
                          )
                        }}
                      </span>
                    </div>
                  </div>

                  <div
                    *ngIf="userDepartmentForm.value.role === 'Other Role'"
                    class="col-5 d-flex"
                  >
                    <div class="form-group mb-md-4 w-100 pl-2 pt-4 mt-2">
                      <label class="label sr-only" for="role">{{
                        'Add Other Role' | translate
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="role"
                        placeholder="{{ 'Add Other Role' | translate }}"
                        formControlName="otherRole"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  {{ 'Please select your department/Specialty' | translate }}
                </div>
                <div class="row no-gutters">
                  <div [ngClass]="otherDepartmentSelected ? 'col-7' : 'col'">
                    <div class="form-group mb-md-4">
                      <!-- <label class="label" for="country">Country</label> -->
                      <div class="mt-2">
                        <ng-select
                          (add)="departmentAdd($event)"
                          (remove)="deptClear($event)"
                          class="custom-slectmenu with-tag"
                          [items]="deparmentArr"
                          bindLabel="name"
                          bindValue="name"
                          placeholder="{{ 'Department/Specialty' | translate }}"
                          formControlName="department"
                          [multiple]="true"
                          (change)="deptSelect($event)"
                          required
                        >
                        </ng-select>

                        <span
                          class="error-msg"
                          *ngIf="
                            (departmentSubmitted ||
                              userDepartmentForm.controls.department.touched) &&
                            userDepartmentForm.controls.department.invalid
                          "
                        >
                          {{ 'Select Department/Specialty' | translate }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="otherDepartmentSelected" class="col-5">
                    <div class="form-group mb-md-4 w-100 pt-2 pl-2">
                      <label class="label sr-only" for="department">{{
                        'Add Other Department' | translate
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="department"
                        placeholder="{{ 'Add Other Department' | translate }}"
                        formControlName="otherDepartment"
                      />
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="
                    countriesSelected.includes(selectedCountry) &&
                    subspecialSelected.includes(selectedCountry)
                  "
                >
                  <div>
                    {{ 'Please select your SubSpecialty' | translate }}
                  </div>
                  <div class="row no-gutters">
                    <div [ngClass]="otherDepartmentSelected ? 'col-7' : 'col'">
                      <div class="form-group mb-md-4">
                        <!-- <label class="label" for="country">Country</label> -->
                        <div class="mt-2">
                          <ng-select
                            (add)="subspecialtyAdd($event)"
                            (remove)="deptClear($event)"
                            class="custom-slectmenu with-tag"
                            [items]="subspecialtyArr"
                            bindLabel="name"
                            bindValue="name"
                            placeholder="{{ 'Subspecialty' | translate }}"
                            formControlName="subspecialties"
                            [multiple]="true"
                            (remove)="subClear($event)"
                            (change)="subspecSelect($event)"
                            required
                          >
                          </ng-select>

                          <span
                            class="error-msg"
                            *ngIf="
                              (departmentSubmitted ||
                                userDepartmentForm.controls.department
                                  .touched) &&
                              userDepartmentForm.controls.department.invalid
                            "
                          >
                            {{ 'Select SubSpecialty' | translate }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="otherSubspecialtySelected" class="col-5">
                      <div class="form-group mb-md-4 w-100 pt-2 pl-2">
                        <label class="label sr-only" for="subspecialties">{{
                          'Add Other Subspecialties' | translate
                        }}</label>
                        <input
                          type="text"
                          class="form-control"
                          id="subspecialties"
                          placeholder="{{
                            'Add Other Subspecialties' | translate
                          }}"
                          formControlName="otherSubspecialties"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="row" *ngIf="hideImage">
              <div class="col-4"></div>
              <div
                class="btn-block d-flex flex-md-row flex-column justify-content-center col-8"
                style="position: absolute; bottom: 0; right: 0"
              >
                <!-- <a
                  style="text-decoration: underline; color: #dc3163"
                  class="mt-1 mr-3"
                  href="javascript:void(0)"
                  (click)="closeModal(true)"
                  >Remind me later</a
                > -->
                <button
                  (click)="navigate(4)"
                  class="large-btn btn-primary ml-md-2 w-60"
                >
                  {{ 'Next' | translate }}
                </button>
              </div>
            </div>
            <div
              *ngIf="!hideImage"
              class="btn-block d-flex flex-column justify-content-center"
            >
              <!-- <a
                style="
                  text-decoration: underline;
                  color: #dc3163;
                  text-align: center;
                "
                class="mt-3"
                href="javascript:void(0)"
                (click)="closeModal(true)"
                >Remind me later</a
              > -->
              <button
                (click)="navigate(4)"
                class="large-btn btn-primary mt-3 w-100"
              >
                {{ 'Next' | translate }}
              </button>
            </div>
          </div>
        </div>

        <div
          class="row"
          style="transition: visibility 0.5s"
          [hidden]="showingElement !== 4"
        >
          <div class="col-6" *ngIf="hideImage">
            <img src="assets/images/aboutusgraphic.png" alt="Laddy Image" />
          </div>
          <div
            [ngClass]="hideImage ? 'col-6' : 'col-12'"
            style="position: relative; transition: ease-in 1s"
          >
            <div [ngClass]="hideImage ? 'mt-5 pt-5' : ''">
              <div>{{ 'Please add your years of experience' | translate }}</div>
              <form [formGroup]="userExperienceForm">
                <div class="form-group mb-md-4">
                  <!-- <label class="label" for="country">Country</label> -->
                  <div class="mt-3">
                    <input
                      type="text"
                      class="form-control"
                      id="domain"
                      placeholder="{{ 'Years of experience' | translate }}"
                      formControlName="experience"
                      (keydown)="restrictKeyPressService.AllowNumbers($event)"
                      maxlength="2"
                    />
                    <span
                      class="error-msg"
                      *ngIf="
                        (experienceSubmitted ||
                          userExperienceForm.controls.experience.touched) &&
                        userExperienceForm.controls.experience.invalid
                      "
                    >
                      {{ 'Please add experience' | translate }}
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="row" *ngIf="hideImage">
              <div class="col-4"></div>
              <div
                class="btn-block d-flex flex-md-row flex-column justify-content-center col-8"
                style="position: absolute; bottom: 0; right: 0"
              >
                <!-- <a
                  style="text-decoration: underline; color: #dc3163"
                  class="mt-1 mr-3"
                  href="javascript:void(0)"
                  (click)="closeModal(true)"
                  >Remind me later</a
                > -->
                <button
                  (click)="navigate(5)"
                  class="large-btn btn-primary ml-md-2 w-60"
                >
                  {{ 'Next' | translate }}
                </button>
              </div>
            </div>
            <div
              *ngIf="!hideImage"
              class="btn-block d-flex flex-column justify-content-center"
            >
              <!-- <a
                style="
                  text-decoration: underline;
                  color: #dc3163;
                  text-align: center;
                "
                class="mt-3"
                href="javascript:void(0)"
                (click)="closeModal(true)"
                >Remind me later</a
              > -->
              <button
                (click)="navigate(5)"
                class="large-btn btn-primary mt-3 w-100"
              >
                {{ 'Next' | translate }}
              </button>
            </div>
          </div>
        </div>

        <div
          class="row"
          style="transition: visibility 0.5s"
          [hidden]="showingElement !== 5"
        >
          <div class="col-6" *ngIf="hideImage">
            <img src="assets/images/learngraphic.png" alt="Laddy Image" />
          </div>
          <div
            [ngClass]="hideImage ? 'col-6' : 'col-12'"
            style="position: relative; transition: ease-in 1s"
          >
            <div [ngClass]="hideImage ? 'mt-3 pt-2' : ''">
              <div>
                Please {{ 'enter your hospital (optional)' | translate }}
              </div>
              <form [formGroup]="userHospitalForm">
                <div class="form-group mb-md-4">
                  <!-- <label class="label" for="country">Country</label> -->
                  <div class="mt-3">
                    <input
                      type="text"
                      class="form-control"
                      id="hospital"
                      placeholder="{{ 'Hospital or Clinic' | translate }}"
                      formControlName="hospital"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="row" *ngIf="hideImage">
              <div class="col-4"></div>
              <div
                class="btn-block d-flex flex-md-row flex-column justify-content-center col-8"
                style="position: absolute; bottom: 0; right: 0"
              >
                <!-- <a
                  style="text-decoration: underline; color: #dc3163"
                  class="mt-1 mr-3"
                  href="javascript:void(0)"
                  (click)="closeModal(true)"
                  >Remind me later</a
                > -->
                <button
                  (click)="navigate(6)"
                  class="large-btn btn-primary ml-md-2 w-60"
                >
                  {{ 'Next' | translate }}
                </button>
              </div>
            </div>
            <div
              *ngIf="!hideImage"
              class="btn-block d-flex flex-column justify-content-center"
            >
              <!-- <a
                style="
                  text-decoration: underline;
                  color: #dc3163;
                  text-align: center;
                "
                class="mt-3"
                href="javascript:void(0)"
                (click)="closeModal(true)"
                >Remind me later</a
              > -->
              <button
                (click)="navigate(6)"
                class="large-btn btn-primary mt-3 w-100"
              >
                {{ 'Next' | translate }}
              </button>
            </div>
          </div>
        </div>

        <div
          class="row"
          style="transition: visibility 0.5s"
          [hidden]="!onboardingCompleted"
        >
          <div class="container align-content-center justify-content-center">
            <div class="pt-2 pb-4 rounded-lg text-center">
              <div
                class="offset-md-1 col-lg-10 col-md-10 col-sm-12 text-center"
                style="margin: auto"
              >
                <a class="navbar-brand logo" href="#">
                  <img
                    width="162"
                    height="51"
                    src="assets/images/logofooter_new.png"
                    alt="no-img"
                  />
                </a>
                <h2 class="text-center mt-5 form-title">
                  {{ 'You re all set!' | translate }}
                </h2>

                <p class="mt-2 qr-text">
                  {{ 'Also checkout our mobile applications' | translate }}
                </p>

                <div class="img-block mt-5">
                  <a href="https://apps.apple.com/us/app/dicerra/id1632367935">
                    <img
                      width="164"
                      height="56"
                      class="playstore"
                      src="assets/images/app-store.png"
                      alt="Available At Ios Store"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.dicerra.med"
                  >
                    <img
                      width="164"
                      height="56"
                      class="appstore"
                      src="assets/images/play-store.png"
                      alt="Available At Play Store"
                    />
                  </a>
                </div>
                <hr />
                <button
                  class="large-btn btn-primary mt-20 mb-5"
                  (click)="closeModal(true)"
                >
                  {{ 'Or Continue Here' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="p-5"
      *ngIf="showingElement !== 0 && userIndustry === 'aviation'"
      style="width: 100%"
    >
      <div
        [ngClass]="
          showingElement !== 1 && !onboardingCompleted
            ? 'onboarding-modal-start'
            : 'onboarding-modal-end'
        "
      >
        <a
          class="d-block text-pink font-semibold"
          href="javascript:void(0)"
          (click)="navigateBack(showingElement - 1)"
          *ngIf="showingElement !== 1 && !onboardingCompleted"
          >{{ 'Back' | translate }}</a
        >
        <!-- <button
          (click)="onboardingCompleted ? closeModal(true) : closeModal(false)"
          type="button"
          class="close pull-right"
          aria-label="Close"
          *ngIf="showingElement === 5 || onboardingCompleted"
        >
          <span aria-hidden="true">×</span>
        </button> -->
      </div>

      <div>
        <div
          style="transition: visibility 0.5s"
          *ngIf="!onboardingCompleted"
          class="mb-5"
        >
          <progressbar [max]="5" [value]="value" [animate]="true"></progressbar>
          <div style="float: right">
            <span>{{ value + '/5' }}</span>
          </div>
        </div>

        <div
          class="row"
          style="transition: visibility 0.5s"
          [hidden]="showingElement !== 1"
        >
          <div class="col-6" *ngIf="hideImage">
            <img src="assets/images/aviationdesk.png" alt="Laddy Image" />
          </div>
          <div
            [ngClass]="hideImage ? 'col-6' : 'col-12'"
            style="position: relative"
          >
            <div [ngClass]="hideImage ? 'mt-5 pt-5' : ''">
              <div>{{ 'Please select your country' | translate }}</div>
              <form [formGroup]="userCountryForm">
                <div class="form-group mb-md-4">
                  <!-- <label class="label" for="country">Country</label> -->
                  <div class="mt-3">
                    <ng-select
                      class="custom-slectmenu"
                      [items]="aviationCountriesArr"
                      bindLabel="name"
                      bindValue="name"
                      placeholder="{{ 'Country' | translate }}"
                      formControlName="country"
                      required
                      (change)="setValueForSelectAviation('country', $event)"
                    >
                    </ng-select>
                    <span
                      class="error-msg"
                      *ngIf="
                        (countrySubmitted ||
                          userCountryForm.controls.country.touched) &&
                        userCountryForm.controls.country.invalid
                      "
                    >
                      {{ ' Select Country' | translate }}
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="row" *ngIf="hideImage">
              <div class="col-4"></div>
              <div
                class="btn-block d-flex flex-md-row flex-column justify-content-center col-8"
                style="position: absolute; bottom: 0; right: 0"
              >
                <!-- <a
                style="text-decoration: underline; color: #dc3163"
                class="mt-1 mr-3"
                href="javascript:void(0)"
                (click)="closeModal(true)"
                >Remind me later</a
              > -->
                <button
                  (click)="navigateAviation(2, 'country')"
                  class="large-btn btn-primary ml-md-2 w-60"
                >
                  {{ 'Next' | translate }}
                </button>
              </div>
            </div>
            <div
              *ngIf="!hideImage"
              class="btn-block d-flex flex-column justify-content-center"
            >
              <!-- <a
              style="
                text-decoration: underline;
                color: #dc3163;
                text-align: center;
              "
              class="mt-3"
              href="javascript:void(0)"
              (click)="closeModal(true)"
              >Remind me later</a
            > -->
              <button
                (click)="navigateAviation(2, 'country')"
                class="large-btn btn-primary mt-3 w-100"
              >
                {{ 'Next' | translate }}
              </button>
            </div>
          </div>
        </div>

        <div
          class="row"
          style="transition: visibility 0.5s"
          [hidden]="showingElement !== 2"
        >
          <div class="col-6" *ngIf="hideImage">
            <img src="assets/images/fighterPilot.png" alt="Laddy Image" />
          </div>
          <div
            [ngClass]="hideImage ? 'col-6' : 'col-12'"
            style="position: relative"
          >
            <div [ngClass]="hideImage ? 'mt-5 pt-5' : ''">
              <div>{{ 'Please select your type' | translate }}</div>
              <form [formGroup]="userTypeForm">
                <div class="form-group mb-md-4">
                  <!-- <label class="label" for="country">Country</label> -->
                  <div class="mt-3">
                    <ng-select
                      class="custom-slectmenu"
                      [items]="typesArr"
                      bindLabel="name"
                      bindValue="name"
                      placeholder="{{ 'Type' | translate }}"
                      formControlName="type"
                      required
                      (change)="setValueForSelectAviation('type', $event)"
                    >
                    </ng-select>
                    <span
                      class="error-msg"
                      *ngIf="
                        (typeSubmitted || userTypeForm.controls.type.touched) &&
                        userTypeForm.controls.type.invalid
                      "
                    >
                      {{ 'Select type' | translate }}
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="row" *ngIf="hideImage">
              <div class="col-4"></div>
              <div
                class="btn-block d-flex flex-md-row flex-column justify-content-center col-8"
                style="position: absolute; bottom: 0; right: 0"
              >
                <!-- <a
                style="text-decoration: underline; color: #dc3163"
                class="mt-1 mr-3"
                href="javascript:void(0)"
                (click)="closeModal(true)"
                >Remind me later</a
              > -->
                <button
                  (click)="navigateAviation(3, 'type')"
                  class="large-btn btn-primary ml-md-2 w-60"
                >
                  {{ 'Next' | translate }}
                </button>
              </div>
            </div>
            <div
              *ngIf="!hideImage"
              class="btn-block d-flex flex-column justify-content-center"
            >
              <!-- <a
              style="
                text-decoration: underline;
                color: #dc3163;
                text-align: center;
              "
              class="mt-3"
              href="javascript:void(0)"
              (click)="closeModal(true)"
              >Remind me later</a
            > -->
              <button
                (click)="navigateAviation(3, 'type')"
                class="large-btn btn-primary mt-3 w-100"
              >
                {{ 'Next' | translate }}
              </button>
            </div>
          </div>
        </div>

        <div
          class="row"
          style="transition: visibility 0.5s"
          [hidden]="showingElement !== 3"
        >
          <div class="col-6 d-flex justify-content-center" *ngIf="hideImage">
            <img
              src="assets/images/pilot.png"
              style="height: 400px !important"
              alt="Laddy Image"
            />
          </div>
          <div
            [ngClass]="hideImage ? 'col-6' : 'col-12'"
            style="position: relative; transition: ease-in 1s"
          >
            <div *ngIf="userTypeForm.value.type === 'Military'">
              <div [ngClass]="hideImage ? 'mt-3 pt-2' : ''">
                <div>
                  {{ 'Please select your place of service' | translate }}
                </div>
                <form [formGroup]="userPlaceOfServiceForm">
                  <div class="form-group mb-md-4">
                    <!-- <label class="label" for="country">Country</label> -->
                    <div class="mt-3">
                      <ng-select
                        class="custom-slectmenu"
                        [items]="placeOfServiceArr"
                        bindLabel="name"
                        bindValue="name"
                        placeholder="{{ 'Place of Service' | translate }}"
                        formControlName="placeOfService"
                        required
                      >
                      </ng-select>
                      <span
                        class="error-msg"
                        *ngIf="
                          (placeOfServiceSubmitted ||
                            userPlaceOfServiceForm.controls.placeOfService
                              .touched) &&
                          userPlaceOfServiceForm.controls.placeOfService.invalid
                        "
                      >
                        {{ ' Select Place of Service' | translate }}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
              <div class="row" *ngIf="hideImage">
                <div class="col-4"></div>
                <div
                  class="btn-block d-flex flex-md-row flex-column justify-content-center col-8"
                  style="position: absolute; bottom: 0; right: 0"
                >
                  <!-- <a
                style="text-decoration: underline; color: #dc3163"
                class="mt-1 mr-3"
                href="javascript:void(0)"
                (click)="closeModal(true)"
                >Remind me later</a
              > -->
                  <button
                    (click)="navigateAviation(4, 'service')"
                    class="large-btn btn-primary ml-md-2 w-60"
                  >
                    {{ 'Next' | translate }}
                  </button>
                </div>
              </div>
              <div
                *ngIf="!hideImage"
                class="btn-block d-flex flex-column justify-content-center"
              >
                <!-- <a
              style="
                text-decoration: underline;
                color: #dc3163;
                text-align: center;
              "
              class="mt-3"
              href="javascript:void(0)"
              (click)="closeModal(true)"
              >Remind me later</a
            > -->
                <button
                  (click)="navigateAviation(4, 'service')"
                  class="large-btn btn-primary mt-3 w-100"
                >
                  {{ 'Next' | translate }}
                </button>
              </div>
            </div>

            <div
              *ngIf="
                userTypeForm.value.type === 'Private' ||
                userTypeForm.value.type === 'Commercial'
              "
            >
              <div [ngClass]="hideImage ? 'mt-3 pt-2' : ''">
                <div>{{ 'Please select your region' | translate }}</div>
                <form [formGroup]="userRegionFormAviation">
                  <div class="form-group mb-md-4">
                    <!-- <label class="label" for="country">Country</label> -->
                    <div class="mt-3">
                      <ng-select
                        class="custom-slectmenu"
                        [items]="aviationRegionsArr"
                        bindLabel="name"
                        bindValue="name"
                        placeholder="{{ 'Region' | translate }}"
                        formControlName="region"
                        required
                      >
                      </ng-select>
                      <span
                        class="error-msg"
                        *ngIf="
                          (regionsSubmitted ||
                            userRegionFormAviation.controls.region.touched) &&
                          userRegionFormAviation.controls.region.invalid
                        "
                      >
                        {{ 'Select Region' | translate }}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
              <div class="row" *ngIf="hideImage">
                <div class="col-4"></div>
                <div
                  class="btn-block d-flex flex-md-row flex-column justify-content-center col-8"
                  style="position: absolute; bottom: 0; right: 0"
                >
                  <!-- <a
                style="text-decoration: underline; color: #dc3163"
                class="mt-1 mr-3"
                href="javascript:void(0)"
                (click)="closeModal(true)"
                >Remind me later</a
              > -->
                  <button
                    (click)="navigateAviation(4, 'region')"
                    class="large-btn btn-primary ml-md-2 w-60"
                  >
                    {{ 'Next' | translate }}
                  </button>
                </div>
              </div>
              <div
                *ngIf="!hideImage"
                class="btn-block d-flex flex-column justify-content-center"
              >
                <!-- <a
              style="
                text-decoration: underline;
                color: #dc3163;
                text-align: center;
              "
              class="mt-3"
              href="javascript:void(0)"
              (click)="closeModal(true)"
              >Remind me later</a
            > -->
                <button
                  (click)="navigateAviation(4, 'region')"
                  class="large-btn btn-primary mt-3 w-100"
                >
                  {{ 'Next' | translate }}
                </button>
              </div>
            </div>

            <div *ngIf="userTypeForm.value.type === 'Airline'">
              <div [ngClass]="hideImage ? 'mt-3 pt-2' : ''">
                <div>{{ 'Please select your airline' | translate }}</div>
                <form [formGroup]="userAirlineForm">
                  <div class="form-group mb-md-4">
                    <!-- <label class="label" for="country">Country</label> -->
                    <div class="mt-3">
                      <ng-select
                        class="custom-slectmenu"
                        [items]="airlinesArr"
                        bindLabel="name"
                        bindValue="name"
                        placeholder="{{ 'Airline' | translate }}"
                        formControlName="airline"
                        required
                      >
                      </ng-select>
                      <span
                        class="error-msg"
                        *ngIf="
                          (airlineSubmitted ||
                            userAirlineForm.controls.airline.touched) &&
                          userAirlineForm.controls.airline.invalid
                        "
                      >
                        {{ ' Select Airline' | translate }}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
              <div class="row" *ngIf="hideImage">
                <div class="col-4"></div>
                <div
                  class="btn-block d-flex flex-md-row flex-column justify-content-center col-8"
                  style="position: absolute; bottom: 0; right: 0"
                >
                  <!-- <a
              style="text-decoration: underline; color: #dc3163"
              class="mt-1 mr-3"
              href="javascript:void(0)"
              (click)="closeModal(true)"
              >Remind me later</a
            > -->
                  <button
                    (click)="navigateAviation(4, 'airline')"
                    class="large-btn btn-primary ml-md-2 w-60"
                  >
                    {{ 'Next' | translate }}
                  </button>
                </div>
              </div>
              <div
                *ngIf="!hideImage"
                class="btn-block d-flex flex-column justify-content-center"
              >
                <!-- <a
            style="
              text-decoration: underline;
              color: #dc3163;
              text-align: center;
            "
            class="mt-3"
            href="javascript:void(0)"
            (click)="closeModal(true)"
            >Remind me later</a
          > -->
                <button
                  (click)="navigateAviation(4, 'airline')"
                  class="large-btn btn-primary mt-3 w-100"
                >
                  {{ 'Next' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row"
          style="transition: visibility 0.5s"
          [hidden]="showingElement !== 4"
        >
          <div class="col-6" *ngIf="hideImage">
            <img src="assets/images/aviationgraphic1.png" alt="Laddy Image" />
          </div>
          <div
            [ngClass]="hideImage ? 'col-6' : 'col-12'"
            style="position: relative"
          >
            <div [ngClass]="hideImage ? 'mt-5 pt-5' : ''">
              <div>{{ 'Please select your expertise' | translate }}</div>
              <form [formGroup]="userExpertiseForm">
                <div class="form-group mb-md-4">
                  <!-- <label class="label" for="country">Country</label> -->
                  <div class="mt-3">
                    <ng-select
                      class="custom-slectmenu"
                      [items]="expertiseArr"
                      bindLabel="name"
                      bindValue="name"
                      placeholder="{{ 'Expertise' | translate }}"
                      formControlName="expertise"
                      required
                    >
                    </ng-select>
                    <span
                      class="error-msg"
                      *ngIf="
                        (expertiseSubmitted ||
                          userExpertiseForm.controls.expertise.touched) &&
                        userExpertiseForm.controls.expertise.invalid
                      "
                    >
                      {{ 'Select expertise' | translate }}
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="row" *ngIf="hideImage">
              <div class="col-4"></div>
              <div
                class="btn-block d-flex flex-md-row flex-column justify-content-center col-8"
                style="position: absolute; bottom: 0; right: 0"
              >
                <!-- <a
              style="text-decoration: underline; color: #dc3163"
              class="mt-1 mr-3"
              href="javascript:void(0)"
              (click)="closeModal(true)"
              >Remind me later</a
            > -->
                <button
                  (click)="navigateAviation(5, 'expertise')"
                  class="large-btn btn-primary ml-md-2 w-60"
                >
                  {{ 'Next' | translate }}
                </button>
              </div>
            </div>
            <div
              *ngIf="!hideImage"
              class="btn-block d-flex flex-column justify-content-center"
            >
              <!-- <a
            style="
              text-decoration: underline;
              color: #dc3163;
              text-align: center;
            "
            class="mt-3"
            href="javascript:void(0)"
            (click)="closeModal(true)"
            >Remind me later</a
          > -->
              <button
                (click)="navigateAviation(5, 'expertise')"
                class="large-btn btn-primary mt-3 w-100"
              >
                {{ 'Next' | translate }}
              </button>
            </div>
          </div>
        </div>

        <div
          class="row"
          style="transition: visibility 0.5s"
          [hidden]="showingElement !== 5"
        >
          <div class="col-6" *ngIf="hideImage">
            <img src="assets/images/aviationPair.png" alt="Laddy Image" />
          </div>
          <div
            [ngClass]="hideImage ? 'col-6' : 'col-12'"
            style="position: relative; transition: ease-in 1s"
          >
            <div [ngClass]="hideImage ? 'mt-5 pt-5' : ''">
              <div>{{ 'Please add your years of experience' | translate }}</div>
              <form [formGroup]="userExperienceForm">
                <div class="form-group mb-md-4">
                  <!-- <label class="label" for="country">Country</label> -->
                  <div class="mt-3">
                    <input
                      type="text"
                      class="form-control"
                      id="domain"
                      placeholder="{{ 'Years of experience' | translate }}"
                      formControlName="experience"
                      (keydown)="restrictKeyPressService.AllowNumbers($event)"
                      maxlength="2"
                    />
                    <span
                      class="error-msg"
                      *ngIf="
                        (experienceSubmitted ||
                          userExperienceForm.controls.experience.touched) &&
                        userExperienceForm.controls.experience.invalid
                      "
                    >
                      {{ 'Please add experience' | translate }}
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="row" *ngIf="hideImage">
              <div class="col-4"></div>
              <div
                class="btn-block d-flex flex-md-row flex-column justify-content-center col-8"
                style="position: absolute; bottom: 0; right: 0"
              >
                <!-- <a
                style="text-decoration: underline; color: #dc3163"
                class="mt-1 mr-3"
                href="javascript:void(0)"
                (click)="closeModal(true)"
                >Remind me later</a
              > -->
                <button
                  (click)="navigateAviation(6, 'experience')"
                  class="large-btn btn-primary ml-md-2 w-60"
                >
                  {{ 'Next' | translate }}
                </button>
              </div>
            </div>
            <div
              *ngIf="!hideImage"
              class="btn-block d-flex flex-column justify-content-center"
            >
              <!-- <a
              style="
                text-decoration: underline;
                color: #dc3163;
                text-align: center;
              "
              class="mt-3"
              href="javascript:void(0)"
              (click)="closeModal(true)"
              >Remind me later</a
            > -->
              <button
                (click)="navigateAviation(6, 'experience')"
                class="large-btn btn-primary mt-3 w-100"
              >
                {{ 'Next' | translate }}
              </button>
            </div>
          </div>
        </div>

        <div
          class="row"
          style="transition: visibility 0.5s"
          [hidden]="!onboardingCompleted"
        >
          <div class="container align-content-center justify-content-center">
            <div class="pt-2 pb-4 rounded-lg text-center">
              <div
                class="offset-md-1 col-lg-10 col-md-10 col-sm-12 text-center"
                style="margin: auto"
              >
                <a class="navbar-brand logo" href="#">
                  <img
                    width="162"
                    height="51"
                    src="assets/images/logofooter_new.png"
                    alt="no-img"
                  />
                </a>
                <h2 class="text-center mt-5 form-title">
                  {{ 'You re all set!' | translate }}
                </h2>

                <p class="mt-2 qr-text">
                  {{ 'Also checkout our mobile applications' | translate }}
                </p>

                <div class="img-block mt-5">
                  <a href="https://apps.apple.com/us/app/dicerra/id1632367935">
                    <img
                      width="164"
                      height="56"
                      class="playstore"
                      src="assets/images/app-store.png"
                      alt="Available At Ios Store"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.dicerra.med"
                  >
                    <img
                      width="164"
                      height="56"
                      class="appstore"
                      src="assets/images/play-store.png"
                      alt="Available At Play Store"
                    />
                  </a>
                </div>
                <hr />
                <button
                  class="large-btn btn-primary mt-20 mb-5"
                  (click)="closeModal(true)"
                >
                  {{ 'Or Continue Here' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #template1>
  <section>
    <div class="onboarding-modal-start">
      <button
        (click)="modalRef.hide()"
        type="button"
        class="close pull-right"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <ng-container *ngIf="privacyPlicy?.content; else not">
      <div
        class="container pt-5"
        *ngIf="privacyPlicy?.content"
        [innerHTML]="privacyPlicy?.content | sanitizeHtml : 'html'"
      ></div>
    </ng-container>
    <ng-template #not>
      <div *ngIf="!privacyPlicy?.content" class="container">
        <div class="row">
          <div class="col-12">
            <div class="mb-4">
              <h3 class="mb-3">Personal Data We Collect.</h3>
              <p>
                We only collect data that helps us achieve the purpose set out
                in this Privacy Policy. We will not collect any additional data
                beyond the data listed below without notifying you first.
              </p>
            </div>
            <div class="mb-4 pt-2">
              <h3 class="mb-3">Data Collected in a Non-Automatic Way</h3>
              <p>
                We may also collect the following data when you perform certain
                functions on our Site:
              </p>
              <ul>
                <li>First and last name</li>
                <li>Age</li>
                <li>Date of birth</li>
                <li>Sex</li>
                <li>Email address</li>
                <li>Phone number</li>
              </ul>
              <p>This data may be collected using the following methods:</p>
              <ul>
                <li>Creating an account and</li>
                <li>Creating an incident and/or solution</li>
              </ul>
            </div>
            <div class="mb-4 pt-2">
              <h3 class="mb-3">How We Use Personal Data</h3>
              <p>
                Data collected on our Site will only be used for the purposes
                specified in this Privacy Policy or indicated on the relevant
                pages of our Site. We will not use your data beyond what we
                disclose in this Privacy Policy.
              </p>
              <p>
                The data we collect when the user performs certain functions may
                be used for the following purposes:
              </p>
              <ul>
                <li>Communication between admin and the user and</li>
                <li>
                  Data collected from reported incidents is anonymized and
                  disaggregated for analysis on preventative measures.
                </li>
              </ul>
            </div>
            <div class="mb-4 pt-2">
              <h3 class="mb-3">Who We Share Personal Data With</h3>
              <h4><u>Employees</u></h4>
              <p>
                We may disclose user data to any member of our organization who
                reasonably needs access to user data to achieve the purposes set
                out in this Privacy Policy
              </p>
              <h4><u>Other Disclosures</u></h4>
              <p>
                We will not sell or share your data with other third parties,
                except in the following cases:
              </p>
              <ul>
                <li>If the law requires it</li>
                <li>If it is required for any legal proceeding</li>
                <li>To prove or protect our legal rights; and</li>
                <li>
                  To buyers or potential buyers of this company in the event
                  that we seek to sell the company
                </li>
              </ul>
              <p>
                To buyers or potential buyers of this company in the event that
                we seek to sell the company
              </p>
            </div>
            <div class="mb-4 pt-2">
              <h3 class="mb-3">How Long We Store Personal Data</h3>
              <p>
                User data will be stored until the purpose the data was
                collected for has been achieved
              </p>
              <p>
                You will be notified if your data is kept for longer than this
                period.
              </p>
            </div>
            <div class="mb-4 pt-2">
              <h3 class="mb-3">How We Protect Your Personal Data</h3>
              <p>
                In order to protect your security, we use the strongest
                available browser encryption and store all of our data on
                servers in secure facilities. All data is only accessible to our
                employees. Our employees are bound by strict confidentiality
                agreements and a breach of this agreement would result in the
                employee's termination.
              </p>
              <p>
                In order to protect your security, we use the strongest
                available browser encryption and store all of our data on
                servers in secure facilities. All data is only accessible to our
                employees. Our employees are bound by strict confidentiality
                agreements and a breach of this agreement would result in the
                employee's termination.
              </p>
            </div>
            <div class="mb-4 pt-2">
              <h3 class="mb-3">International Data Transfers</h3>
              <p>We transfer user personal data to the following countries:</p>
              <ul>
                <li>
                  Data on incidents and solutions are shared between Canada and
                  New Zealand.
                </li>
              </ul>
              <p>
                When we transfer user personal data we will protect that data as
                described in this Privacy Policy and comply with applicable
                legal requirements for transferring personal data
                internationally.
              </p>
            </div>
            <div class="mb-4 pt-2">
              <h3 class="mb-3">Children</h3>
              <p>
                We do not knowingly collect or use personal data from children
                under 13 years of age. If we learn that we have collected
                personal data from a child under 13 years of age, the personal
                data will be deleted as soon as possible. If a child under 13
                years of age has provided us with personal data their parent or
                guardian may contact our privacy officer.
              </p>
            </div>
            <div class="mb-4 pt-2">
              <h3 class="mb-3">
                How to Access, Modify, Delete, or Challenge the Data Collected
              </h3>
              <p>
                If you would like to know if we have collected your personal
                data, how we have used your personal data, if we have disclosed
                your personal data and to who we disclosed your personal data,
                or if you would like your data to be deleted or modified in any
                way, please contact our privacy officer here:
              </p>
              <h4>Jonathan Bregman</h4>
              <h4><i>jb@dicerra.com</i></h4>
            </div>
            <div class="mb-4 pt-2">
              <h3 class="mb-3">
                How to Opt-Out of Data Collection, Use or Disclosure
              </h3>
              <p>
                In addition to the method(s) described in the How to
                <i> Access, Modify, Delete, or Challenge the Data Collected</i>
                section, we provide the following specific opt-out methods for
                the forms of collection, use, or disclosure of your personal
                data specified below:
              </p>
              <ul>
                <li>
                  You can opt out of publicly disclosing personal information.
                  Users may select checkboxes to determine their disclosure of
                  information.
                </li>
              </ul>
            </div>
            <div class="mb-4 pt-2">
              <h3 class="mb-3">Modifications</h3>
              <p>
                This Privacy Policy may be amended from time to time in order to
                maintain compliance with the law and to reflect any changes to
                our data collection process. When we amend this Privacy Policy
                we will update the "Effective Date" at the top of this Privacy
                Policy. We recommend that our users periodically review our
                Privacy Policy to ensure that they are notified of any updates.
                If necessary, we may notify users by email of changes to this
                Privacy Policy.
              </p>
            </div>
            <div class="mb-4 pt-2">
              <h3 class="mb-3">Contact Information</h3>
              <p>
                If you have any questions, concerns or complaints, you can
                contact our privacy officer, Jonathan Bregman, at:
              </p>
              <h4><i>jb@dicerra.com</i></h4>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </section>
</ng-template>

<ng-template #template2>
  <section class="m-5">
    <div class="onboarding-modal-start">
      <button
        (click)="modalRef.hide()"
        type="button"
        class="close pull-right"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <ng-container *ngIf="termsAndConditions; else not">
      <div
        *ngIf="termsAndConditions"
        [innerHTML]="termsAndConditions | sanitizeHtml : 'html'"
      ></div>
    </ng-container>
    <ng-template #not>
      <section>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="mb-4">
                <h4 class="mb-3">Intellectual Property</h4>
                <p>
                  All content published and made available on our Site is the
                  property of Dicerra and the Site's creators. This includes,
                  but is not limited to images, text, logos, documents,
                  downloadable files and anything that contributes to the
                  composition of our Site.
                </p>
              </div>
              <div class="mb-4 pt-2">
                <h4 class="mb-3">Acceptable Use</h4>
                <p>
                  As a user of our Site, you agree to use our Site legally, not
                  to use our Site for illegal purposes, and not to
                </p>
                <ul>
                  <li>Harass or mistreat other users of our Site</li>
                  <li>Violate the rights of other users of our Site</li>
                  <li>Violate the rights of other users of our Site</li>
                  <li>Hack into the account of another user of the Site</li>
                  <li>Act in any way that could be considered fraudulent or</li>
                  <li>
                    Post any material that may be deemed inappropriate or
                    offensive
                  </li>
                </ul>
                <p>
                  If we believe you are using our Site illegally or in a manner
                  that violates these Terms and Conditions, we reserve the right
                  to limit, suspend or terminate your access to our Site. We
                  also reserve the right to take any legal steps necessary to
                  prevent you from accessing our Site.
                </p>
              </div>
              <div class="mb-4 pt-2">
                <h4 class="mb-3">User Contributions</h4>
                <p>Users may post the following information on our Site:</p>
                <ul>
                  <li>Photos</li>
                  <li>Videos and</li>
                  <li>Public comments</li>
                </ul>
                <p>
                  By posting publicly on our Site, you agree not to act
                  illegally or violate these Terms and Conditions.
                </p>
              </div>
              <div class="mb-4 pt-2">
                <h4 class="mb-3">Accounts</h4>
                <p>
                  When you create an account on our Site, you agree to the
                  following
                </p>
                <ul>
                  <li>
                    You are solely responsible for your account and the security
                    and privacy of your account, including passwords or
                    sensitive information attached to that account; and
                  </li>
                  <li>
                    All personal information you provide to us through your
                    account is up to date, accurate, and truthful and that you
                    will update your personal information if it changes
                  </li>
                </ul>
                <p>
                  We reserve the right to suspend or terminate your account if
                  you are using our Site illegally or if you violate these Terms
                  and Conditions.
                </p>
              </div>
              <div class="mb-4 pt-2">
                <h4 class="mb-3">User Goods and Services</h4>
                <p>
                  ur Site allows users to sell goods and services. We do not
                  assume any responsibility for the goods and services users
                  sell on our Site. We cannot guarantee the quality or accuracy
                  of any goods and services sold by users on our Site. However,
                  if we are made aware that a user is violating these Terms and
                  Conditions, we reserve the right to suspend or prohibit the
                  user from selling goods and services on our Site.
                </p>
              </div>
              <div class="mb-4 pt-2">
                <h4 class="mb-3">Limitation of Liability</h4>
                <p>
                  Dicerra and our directors, officers, agents, employees,
                  subsidiaries, and affiliates will not be liable for any
                  actions, claims, losses, damages, liabilities and expenses
                  including legal fees from your use of the Site
                </p>
              </div>
              <div class="mb-4 pt-2">
                <h4 class="mb-3">Indemnity</h4>
                <p>
                  Except where prohibited by law, by using this Site you
                  indemnify and hold harmless Dicerra and our directors,
                  officers, agents, employees, subsidiaries, and affiliates from
                  any actions, claims, losses, damages, liabilities and expenses
                  including legal fees arising out of your use of our Site or
                  your violation of these Terms and Conditions.
                </p>
              </div>
              <div class="mb-4 pt-2">
                <h4 class="mb-3">Applicable Law</h4>
                <p>
                  These Terms and Conditions are governed by the laws of the
                  Province of Ontario.
                </p>
              </div>
              <div class="mb-4 pt-2">
                <h4 class="mb-3">Severability</h4>
                <p>
                  If at any time any of the provisions set forth in these Terms
                  and Conditions are found to be inconsistent or invalid under
                  applicable laws, those provisions will be deemed void and will
                  be removed from these Terms and Conditions. All other
                  provisions will not be affected by the removal and the rest of
                  these Terms and Conditions will still be considered valid.
                </p>
              </div>
              <div class="mb-4 pt-2">
                <h4 class="mb-3">Changes</h4>
                <p>
                  These Terms and Conditions may be amended from time to time in
                  order to maintain compliance with the law and to reflect any
                  changes to the way we operate our Site and the way we expect
                  users to behave on our Site. We will notify users by email of
                  changes to these Terms and Conditions or post a notice on our
                  Site.
                </p>
              </div>
              <div class="mb-4 pt-2">
                <h4 class="mb-3">Contact Details</h4>
                <p>
                  Please contact us if you have any questions or concerns. Our
                  contact details are as follows: You can also contact us
                  through the feedback form available on our Site
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ng-template>
  </section>
</ng-template>
