import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class GlobalFilterService {
  public globalFilter = new Subject<any>();
  constructor() {}

  sendGlobalFilter(message: any, reset: boolean) {
    this.globalFilter.next({ data: message, reset: reset });
  }

  getGlobalFilter(): Observable<any> {
    return this.globalFilter.asObservable();
  }
}
