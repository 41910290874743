import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Ng ng-select
import { NgSelectModule } from '@ng-select/ng-select';
// Boostrap
import { ModalModule } from 'ngx-bootstrap/modal';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
// Plugins
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
// OWl carousalImport the library
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
// Component
import { FooterComponent } from '../components/footer/footer.component';
import { HeaderComponent } from '../components/header/header.component';
import { GiveSolutionsComponent } from '../components/give-solutions/give-solutions.component';
import { ViewMediaComponent } from '../modals/view-media/view-media.component';
import { SideIncidentDetailsComponent } from '../components/side-incident-details/side-incident-details.component';
import { CustomPipe } from 'src/app/common/services/service/customPipe.pipe';
import { CharacterComponent } from '../components/character/character.component';
import { TrimPipe } from '../../common/services/service/trim.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
  TranslateModule,
  TranslateService,
  LangChangeEvent,
} from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    GiveSolutionsComponent,
    ViewMediaComponent,
    SideIncidentDetailsComponent,
    CustomPipe,
    CharacterComponent,
    TrimPipe,
  ],
  imports: [
    TranslateModule.forChild(),
    FormsModule,
    RouterModule,
    CommonModule,
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
    CarouselModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    CollapseModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    NgOtpInputModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    ImageCropperModule,
    TooltipModule.forRoot(),
    ShareButtonsModule.withConfig({
      debug: true,
    }),
    ShareIconsModule,
    NgxIntlTelInputModule,
    InfiniteScrollModule,
    RecaptchaModule,
    RecaptchaFormsModule,

    ProgressbarModule.forRoot(),
  ],
  exports: [
    // Modules
    FormsModule,
    ModalModule,
    // Components
    CharacterComponent,
    HeaderComponent,
    FooterComponent,
    GiveSolutionsComponent,
    SideIncidentDetailsComponent,
    ViewMediaComponent,
    AccordionModule,
    CarouselModule,
    NgSelectModule,
    BsDatepickerModule,
    TimepickerModule,
    CollapseModule,
    PopoverModule,
    TooltipModule,
    TabsModule,
    BsDropdownModule,
    NgOtpInputModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ImageCropperModule,
    ShareButtonsModule,
    ShareIconsModule,
    NgxIntlTelInputModule,
    CustomPipe,
    TrimPipe,
    InfiniteScrollModule,
    ProgressbarModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  entryComponents: [],
})
export class SharedModule {
  constructor(
    public translationService: TranslateService,
    public localStorageService: LocalStorageService
  ) {
    let language = this.localStorageService.getToken('language');
    if (language) {
      this.translationService.use(language);
    }
    this.translationService.store.onLangChange.subscribe(
      (lang: LangChangeEvent) => {
        this.translationService.use(lang.lang);
      }
    );
  }
}
