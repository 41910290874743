<footer class="footer">
  <div
    class="container d-flex justify-content-center align-content-center align-items-center flex-column"
  >
    <a class="navbar-brand logo logo-sm" href="#">
      <img
        width="350"
        height="60"
        src="assets/images/logofooter_new.png"
        alt="no-img"
      />
    </a>
    <div class="row">
      <ul class="f-navbar navbar-nav ml-auto justify-content-center">
        <li [ngClass]="{ active: router.url.includes(URLConstants.HOMEPAGE) }">
          <a [routerLink]="[URLConstants.HOMEPAGE]" class="footer-text">{{
            'Home' | translate
          }}</a>
        </li>
        <li [ngClass]="{ active: router.url.includes(URLConstants.ABOUT) }">
          <a
            [routerLink]="[URLConstants.ABOUT]"
            class="footer-text"
            (click)="setIndustry('healthcare')"
            >{{ 'Healthcare About Us' | translate }}</a
          >
        </li>
        <li [ngClass]="{ active: router.url.includes(URLConstants.ABOUT) }">
          <a
            [routerLink]="[URLConstants.ABOUT]"
            class="footer-text"
            (click)="setIndustry('aviation')"
            >{{ 'Aviation About Us' | translate }}</a
          >
        </li>
        <li [ngClass]="{ active: router.url.includes(URLConstants.CONTACT) }">
          <a [routerLink]="[URLConstants.CONTACT]" class="footer-text">{{
            'Contact Us' | translate
          }}</a>
        </li>
        <li [ngClass]="{ active: router.url.includes(URLConstants.FAQS) }">
          <a [routerLink]="[URLConstants.FAQS]" class="footer-text">{{
            'FAQs' | translate
          }}</a>
        </li>
        <li
          [ngClass]="{
            active: router.url.includes(URLConstants.TERMSANDCONDITION)
          }"
        >
          <a
            [routerLink]="[URLConstants.TERMSANDCONDITION]"
            class="footer-text"
            >{{
              'Terms &
            Conditions' | translate
            }}</a
          >
        </li>
        <li
          [ngClass]="{
            active: router.url.includes(URLConstants.PRIVACYPOLICY)
          }"
        >
          <a [routerLink]="[URLConstants.PRIVACYPOLICY]" class="footer-text">{{
            'Privacy Policy' | translate
          }}</a>
        </li>
        <li>
          <div class="col-md-12">
            <ng-select
              [searchable]="false"
              [clearable]="false"
              [items]="languageArr"
              class="custom-slectmenu"
              bindLabel="name"
              bindValue="value"
              placeholder="{{ 'Language' | translate }}"
              [(ngModel)]="selectedLanguage"
              (change)="onLanguageSelection($event)"
              [ngModelOptions]="{ standalone: true }"
            >
            </ng-select>
          </div>
        </li>
      </ul>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="footer-copyright-block">
          <div class="available-on">
            <p class="footer-text-title text-center">
              {{ 'Available On' | translate }}
            </p>
            <div class="img-block">
              <a href="https://apps.apple.com/us/app/dicerra/id1632367935">
                <img
                  width="164"
                  height="56"
                  class="playstore"
                  src="assets/images/iOS_btn.png"
                  alt="Available At Ios Store"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.dicerra.med"
              >
                <img
                  width="164"
                  height="56"
                  class="appstore"
                  src="assets/images/play_store_btn.png"
                  alt="Available At Play Store"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="margin-top: 61px">
      <ul class="social-list list-unstyled list">
        <li>
          <a
            href="https://www.facebook.com/dicerra"
            target="_blank"
            rel="noopener noreferrer"
            class="mx-1"
          >
            <span class="icon-facebook"> </span>
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/_Dicerra_"
            target="_blank"
            rel="noopener noreferrer"
            class="mx-1"
          >
            <span class="icon-twitter"> </span>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/81640529"
            target="_blank"
            rel="noopener noreferrer"
            class="mx-1"
          >
            <span class="icon-linkedin"> </span>
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/@dicerra1"
            target="_blank"
            rel="noopener noreferrer"
            class="mx-1"
          >
            <span class="icon-youtube"></span>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/_Dicerra_/"
            target="_blank"
            rel="noopener noreferrer"
            class="mx-1"
          >
            <span class="icon-instagram"></span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</footer>
<div
  class="d-flex justify-content-center"
  style="
    background: #00aeef 0% 0% no-repeat padding-box;
    height: 82px;
    width: 100%;
  "
>
  <p
    class="copyright-txt about-banner-content-font"
    style="margin-bottom: 30px"
  >
    {{ 'Copyright' | translate }} &copy; {{ currentYear }} Dicerra.
    {{ 'All rights reserved.' | translate }}
  </p>
</div>
