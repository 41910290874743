<style>
  .pointer-cursor {
    cursor: pointer;
  }
  .industry-icon-air {
    position: relative;
    top: 28px;
    right: 16px;
  }
  .profile-img-Asrs {
    width: 45px;
    border-radius: 45px;
    overflow: hidden;
    height: auto;
  }
</style>
<div class="feedblock new-side-incident-card">
  <div class="d-flex justify-content-end">
    <span class="dot-dropdown mr-4 mt-3" dropdown>
      <span
        class="icon-Georgia-Gardner"
        dropdownToggle
        (click)="(false)"
      ></span>
      <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu">
        <li (click)="updateFeedsocialData(incidentData._id, false)">
          <div class="d-flex flex-row align-items-center">
            <span
              style="font-size: 15px"
              class="icon-heart pt-1"
              [ngClass]="incidentData.isFavorite ? 'active' : ''"
              (click)="updateFeedsocialData(incidentData._id, false)"
            ></span>

            <span
              [ngClass]="incidentData.isFavorite ? 'favorite-active' : ''"
              class="ml-2"
              >{{ 'favourite' | translate }}</span
            >
          </div>
        </li>
        <div *ngIf="incidentData.reportedPending; else elseComponent">
          <li>
            <span (click)="removeReport(incidentData._id, 'incident')">{{
              'Remove report' | translate
            }}</span>
          </li>
        </div>
        <ng-template #elseComponent>
          <li *ngFor="let report of reportList">
            <span (click)="submitReport(report.id, incidentData._id)">{{
              report.name
            }}</span>
          </li>
        </ng-template>
      </ul>
    </span>
  </div>

  <div class="pl-4 pr-2">
    <!-- <div class="feed-user d-flex align-items-center">
      <picture class="profile-img">
        <img
          [src]="incidentData.author?.photo"
          alt="no-image"
          (error)="img.src = uploadedImage"
          #img
        />
      </picture>
      <div class="ml-3 mr-3">
        <h4 class="profile-name font-semibold mb-0">
          {{
            incidentData.author?.fakeUsername
              ? incidentData.author?.fakeUsername
              : '--'
          }}
        </h4>
        <div class="row no-gutters">
          <div class="col" *ngIf="incidentData.author?.isDepartmentVisible">
            {{
              incidentData.author?.department
                ? incidentData.author?.department
                : ''
            }}
          </div>
          <div
            *ngIf="
              incidentData.author?.isDepartmentVisible &&
              incidentData.author?.isExperienceVisible
            "
            class="mx-2"
            style="
              width: 4px;
              height: 4px;
              /* UI Properties */
              background: #707070 0% 0% no-repeat padding-box;
              border: 1px solid #707070;
              opacity: 1;
              border-radius: 50px;
              margin-top: 12px;
              margin-bottom: auto;
            "
          ></div>
          <div class="col" *ngIf="incidentData.author?.isExperienceVisible">
            {{
              incidentData.author?.yearsOfExperience
                ? incidentData.author?.yearsOfExperience
                : '0'
            }}
            {{ incidentData.author?.yearsOfExperience > 1 ? 'YoE' : 'YoE' }}
          </div>
        </div>
        <p class="post-date mb-0">
          {{ incidentData.createdAt }}
        </p>
      </div>
      <div class="mr-auto"></div> -->
    <!-- <div class="mr-auto">
        <p *ngIf="incidentData.author?.isExperienceVisible">
          {{
            incidentData.author?.yearsOfExperience
              ? incidentData.author?.yearsOfExperience
              : '0'
          }}
          {{
            incidentData.author?.yearsOfExperience > 1
              ? 'years of experience'
              : 'years of experience'
          }}
        </p>
        <p *ngIf="incidentData.author?.isDepartmentVisible">
          {{
            incidentData.author?.department
              ? incidentData.author?.department
              : ''
          }}
        </p> -->
    <!-- <p *ngIf="incidentData.author?.isDepartmentVisible">{{incidentData.author?.department ?
                        incidentData.author?.department : ''}}</p> -->
    <!-- </div> -->

    <!-- <span class="dot-dropdown" dropdown>
        <span
          class="icon-flag-outline"
          [ngClass]="
            incidentData.reportedPending ? 'active icon-flag disabled' : ''
          "
          dropdownToggle
          (click)="(false)"
        ></span>
        <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu">
          <li *ngFor="let report of reportList">
            <span (click)="submitReport(report.id, incidentData._id)">{{
              report.name
            }}</span>
          </li>
        </ul>
      </span> -->
    <!-- </div> -->
    <div id="{{ incidentData._id }}">
      <div class="feed-user row no-gutters">
        <div
          style="width: 45px"
          class="d-flex align-items-center justify-content-center mr-2"
        >
          <img
            [ngClass]="
              incidentData.author?.fakeUsername == 'ASRS'
                ? 'profile-img-Asrs'
                : 'profile-img-solution'
            "
            [src]="incidentData.author?.photo"
            alt="no-image"
            (error)="img.src = uploadedImage"
            #img
          />
        </div>
        <!-- <span
          *ngIf="
            incidentData.author?.industry === 'aviation' &&
            !incidentData.isAdminPost
          "
          [ngClass]="
            incidentData?.typeExperience == 0 &&
            incidentData.author?.fakeUsername == 'ASRS' &&
            incidentData.totalExperience == 0
              ? 'industry-icon-air'
              : 'industry-icon-detail'
          "
          class="industry-span text-white mb-2"
        >
          <fa-icon style="font-size: 10px" [icon]="faPlane"></fa-icon
        ></span> -->

        <!-- <span
          *ngIf="
            incidentData.author?.industry === 'healthcare' &&
            !incidentData.isAdminPost
          "
          class="industry-icon-detail industry-span-second text-white mb-2"
        >
          <fa-icon style="font-size: 13px" [icon]="faHealth"></fa-icon
        ></span> -->

        <div class="col-9">
          <div>
            <div
              [ngClass]="incidentData?.typeExperience ? 'd-flex flex-row' : ''"
            >
              <h4 class="profile-name font-semibold mb-0">
                {{
                  incidentData.author?.fakeUsername
                    ? incidentData.author?.fakeUsername
                    : ''
                }}
              </h4>
              <div *ngIf="incidentData?.typeExperience">
                <p
                  *ngIf="
                    !incidentData.isAsrs && !incidentData.isTsb;
                    else postdate
                  "
                  class="post-date mb-0 ml-2"
                >
                  {{
                    incidentData.createdAt
                      ? incidentData.createdAt
                      : '03 jul
                        2021'
                  }}
                </p>

                <ng-template #postdate>
                  <p class="post-date mb-0 ml-2">
                    {{
                      incidentData.postdate
                        ? incidentData.postdate
                        : '03 jul
                          2021'
                    }}
                  </p>
                </ng-template>
              </div>
            </div>
            <div class="d-flex flex-row">
              <div
                class="text-truncate"
                *ngIf="incidentData.author?.isDepartmentVisible"
                placement="bottom"
                tooltip="  {{
                  incidentData.author?.department.length > 0
                    ? incidentData.author?.department
                    : incidentData.author?.otherDepartment == ''
                    ? incidentData.author?.expertise
                    : incidentData.author?.otherDepartment
                }}"
              >
                {{
                  incidentData.author?.department.length > 0
                    ? incidentData.author?.department
                    : incidentData.author?.otherDepartment == ''
                    ? incidentData.author?.expertise
                    : incidentData.author?.otherDepartment
                }}
              </div>
              <div
                *ngIf="
                  incidentData.author?.isDepartmentVisible &&
                  incidentData.author?.isExperienceVisible
                "
                class="mx-2"
                style="
                  width: 4px;
                  height: 4px;
                  /* UI Properties */
                  background: #707070 0% 0% no-repeat padding-box;
                  border: 1px solid #707070;
                  opacity: 1;
                  border-radius: 50px;
                  margin-top: 12px;
                  margin-bottom: auto;
                "
              ></div>
              <div
                class="text-truncate"
                *ngIf="incidentData.author?.isExperienceVisible"
                tooltip=" {{
                  incidentData.author?.yearsOfExperience
                    ? incidentData.author?.yearsOfExperience
                    : '0'
                }}
              {{ incidentData.author?.yearsOfExperience > 1 ? 'YoE' : 'YoE' }}"
                placement="bottom"
              >
                {{
                  incidentData.author?.yearsOfExperience
                    ? incidentData.author?.yearsOfExperience
                    : '0'
                }}
                {{ incidentData.author?.yearsOfExperience > 1 ? 'YoE' : 'YoE' }}
              </div>
              <div
                class="text-truncate"
                *ngIf="incidentData?.totalExperience"
                tooltip=" {{
                  incidentData.totalExperience
                    ? incidentData.totalExperience
                    : '0'
                }}
              {{
                  incidentData.totalExperience > 1
                    ? 'Hours of total crew experience'
                    : 'YoE'
                }}"
                placement="bottom"
              >
                {{
                  incidentData.totalExperience
                    ? incidentData.totalExperience
                    : '0'
                }}
                {{
                  incidentData.totalExperience > 1
                    ? 'Hours of total crew experience'
                    : 'YoE'
                }}
              </div>
            </div>
            <div
              class="text-truncate"
              *ngIf="incidentData?.typeExperience"
              tooltip=" {{
                incidentData.typeExperience ? incidentData.typeExperience : '0'
              }}
              {{
                incidentData.typeExperience > 1
                  ? 'Hours of type experience'
                  : 'YoE'
              }}"
              placement="bottom"
            >
              {{
                incidentData.typeExperience ? incidentData.typeExperience : '0'
              }}
              {{
                incidentData.typeExperience > 1
                  ? 'Hours of type experience'
                  : 'YoE'
              }}
            </div>
            <div *ngIf="!incidentData?.typeExperience">
              <!-- <p class="post-date mb-0">
                {{
                  incidentData.createdAt
                    ? incidentData.createdAt
                    : '03 jul
                        2021'
                }}
              </p> -->

              <p
                *ngIf="
                  !incidentData.isAsrs && !incidentData.isTsb;
                  else postdate
                "
                class="post-date mb-0"
              >
                {{
                  incidentData.createdAt
                    ? incidentData.createdAt
                    : '03 jul
                    2021'
                }}
              </p>

              <ng-template #postdate>
                <p class="post-date mb-0">
                  {{
                    incidentData.postdate
                      ? incidentData.postdate
                      : '03 jul
                      2021'
                  }}
                </p>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p
      *ngIf="incidentData.seeOriginal"
      style="font-family: sans-serif"
      class="font-bold mt-2 mb-0 pr-2"
    >
      {{ incidentData.incidentTitle ? incidentData.incidentTitle : '--' }}
    </p>
    <div *ngIf="!incidentData.seeOriginal">
      <p
        *ngIf="
          localLanguage === incidentData.language ||
          incidentData.translationCount === 0
        "
        style="font-family: sans-serif"
        class="font-bold mt-2 mb-0 pr-2"
      >
        {{ incidentData.incidentTitle ? incidentData.incidentTitle : '--' }}
      </p>

      <div
        *ngIf="
          localLanguage !== incidentData.language &&
          incidentData.translationCount > 0
        "
      >
        <div *ngFor="let translation of incidentData.translations">
          <!-- prettier-ignore -->
          <p
        *ngIf="((translation.shortCode === localLanguage) && (translation.isActive && !translation.isDeleted) )
        "
        style="font-family: sans-serif" class="font-bold mt-2 mb-0 pr-2"
        
      >
        {{
          translation.mainTitle
            ? translation.mainTitle
            : ('' | titlecase)
        }}
      </p>

          <p
            *ngIf="
              translation.shortCode === localLanguage &&
              (!translation.isActive || translation.isDeleted)
            "
            style="font-family: sans-serif"
            class="font-bold mt-2 mb-0 pr-2"
          >
            {{ incidentData.incidentTitle ? incidentData.incidentTitle : '--' }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- feed header -->

  <div class="post-image">
    <div
      [ngClass]="{
        'multipost-image': incidentData.media?.length > 2,
        'post-two': incidentData.media?.length == 2,
        'post-image': incidentData.media?.length == 1
      }"
    >
      <div
        [ngClass]="
          incidentData.media?.length > 1 ? 'multiple-post' : 'post-one'
        "
      >
        <div
          *ngFor="
            let media of incidentData.media | slice : 0 : 3;
            let ind = index;
            let first = first;
            let last = last
          "
          (click)="
            openModalWithClass(
              postdetailpopup,
              incidentData.media,
              incidentData
            )
          "
        >
          <picture
            class="feed-img pointer-cursor"
            [ngClass]="last ? 'final-img' : ''"
            *ngIf="media.fileType == 'image'"
          >
            <img
              width="613"
              height="450"
              [src]="media.filePath"
              alt="Media"
              *ngIf="media.fileType == 'image'"
              (error)="img.src = noImage"
              #img
            />
            <div class="more-img" *ngIf="last && incidentData.media.length > 3">
              <span>{{ incidentData.media.length - 3 }} +</span>
            </div>
          </picture>

          <picture class="feed-img final-img">
            <video
              class="w-100 video-strach"
              id="video{{ ind }}"
              style="height: 100%"
              controlsList="nodownload"
              [muted]="true"
              playsinline
              *ngIf="media.fileType == 'video'"
            >
              <source [src]="media.filePath" />
            </video>
            <div class="more-img" *ngIf="last && incidentData.media.length > 3">
              <span>{{ incidentData.media.length - 3 }} +</span>
            </div>
          </picture>
        </div>
      </div>
    </div>
  </div>
  <!-- image sec -->
  <div class="feed-action-block px-4">
    <p
      *ngIf="incidentData.seeOriginal"
      style="white-space: pre-line; font-family: sans-serif"
    >
      {{ incidentData?.incidentDescription }}
    </p>
    <div *ngIf="incidentData.seeOriginal">
      <a
        (click)="incidentData.seeOriginal = !incidentData.seeOriginal"
        class="view_more font-semibold"
        style="color: #3bade2; cursor: pointer"
        >{{
          incidentData.seeOriginal
            ? ('See Translation' | translate)
            : ('See Original' | translate)
        }}</a
      >
    </div>
    <div *ngIf="!incidentData.seeOriginal">
      <p
        *ngIf="
          localLanguage === incidentData.language ||
          incidentData.translationCount === 0
        "
        style="white-space: pre-line; font-family: sans-serif"
      >
        {{ incidentData?.incidentDescription }}
      </p>
      <div
        *ngIf="
          localLanguage !== incidentData.language &&
          incidentData.translationCount > 0
        "
      >
        <div *ngFor="let translation of incidentData.translations">
          <!-- prettier-ignore -->
          <p
        *ngIf="((translation.shortCode === localLanguage) && (translation.isActive && !translation.isDeleted) )
        "
        style="
          white-space: pre-line;
          font-family: sans-serif;
          cursor: pointer;
        "
     
      >
      {{
        translation.description
          ? translation.description
          : ('' | titlecase)
      }}
      </p>
          <!-- prettier-ignore -->
          <a
          *ngIf="
            ((translation.shortCode === localLanguage) && (translation.isActive && !translation.isDeleted) )
            "
            (click)="incidentData.seeOriginal = !incidentData.seeOriginal"
            class="view_more font-semibold"
            style="color: #3bade2; cursor: pointer"
            >{{
              incidentData.seeOriginal  ? ('See Translation'| translate)
              : ('See Original'| translate)
            }}</a
          >
          <p
            *ngIf="
              translation.shortCode === localLanguage &&
              (!translation.isActive || translation.isDeleted)
            "
            style="white-space: pre-line; font-family: sans-serif"
          >
            {{ incidentData?.incidentDescription }}
          </p>
        </div>
      </div>
    </div>
    <p class="font-medium" style="cursor: pointer">
      <ng-container
        *ngIf="
          incidentData?.tags && incidentData?.tags.length > 0;
          else showHumanFactors
        "
      >
        <ng-container *ngFor="let tag of incidentData?.tags">
          <strong
            *ngIf="incidentData.seeOriginal"
            style="cursor: pointer"
            tooltip="{{ tag.definition }}"
            placement="bottom"
            class="mr-1"
            >{{ tag?.name ? '#' + tag.name : '' }}</strong
          >

          <ng-container *ngIf="!incidentData.seeOriginal">
            <strong
              *ngIf="
                tag.translationCount === 0 || localLanguage === tag.language
              "
              style="cursor: pointer"
              tooltip="{{ tag.definition }}"
              placement="bottom"
              class="mr-1"
              >{{ tag?.name ? '#' + tag.name : '' }}</strong
            >
            <ng-container
              *ngIf="localLanguage !== tag.language && tag.translationCount > 0"
            >
              <ng-container *ngFor="let translation of tag.translations">
                <!-- prettier-ignore -->
                <strong
                  *ngIf="
                  ((translation.shortCode === localLanguage) && (translation.isActive && !translation.isDeleted) ) "
                    style="cursor: pointer"
                    tooltip="{{ translation.description }}"
                    placement="bottom"
                    class="mr-1"
                  >
                    {{
                      translation?.mainTitle
                        ? '#' + translation.mainTitle
                        : ''
                    }}
                  </strong>
                <!-- prettier-ignore -->
                <strong
                  *ngIf="(translation.shortCode === localLanguage &&
                  (!translation.isActive || translation.isDeleted) )"
                  style="cursor: pointer"
                  tooltip="{{ tag.definition }}"
                  placement="bottom"
                  class="mr-1"
                  >{{ tag?.name ?  '#' + tag.name : '' }}</strong
                >
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #showHumanFactors>
        <ng-container *ngFor="let humanFactors of incidentData?.humanFactors">
          <strong style="cursor: pointer" placement="bottom" class="mr-1">{{
            humanFactors ? '#' + humanFactors : ''
          }}</strong>
        </ng-container>
      </ng-template>
    </p>
    <!-- <strong
      style="cursor: pointer"
      tooltip="{{ tag.definition }}"
      placement="bottom"
      *ngFor="let tag of incidentData.tags"
      >{{ tag.name ? tag.name + '' : '' }}</strong
    > -->

    <div
      *ngIf="
        (incidentData.isAsrs || incidentData.isTsb) && incidentData.aircraft
      "
      class="mt-2 mb-1 d-flex flex-row"
    >
      <fa-icon
        style="font-size: 13px; color: rgb(88, 85, 85); margin-top: 1px"
        [icon]="faPlane"
      ></fa-icon>
      <p class="ml-2">{{ incidentData.aircraft }}</p>
    </div>
    <p
      *ngIf="incidentData.isTsb"
      class="pointer-cursor incident-url"
      style="white-space: pre-line; font-family: sans-serif"
      (click)="navigateToExternalLink(incidentData.url)"
    >
      For more details, you can access the final report on this incident from
      here.
    </p>
    <div *ngIf="incidentData.acronyms">
      <div *ngIf="incidentData.acronyms.length > 0">
        <div class="d-flex flex-row">
          <div style="font-family: sans-serif; margin-top: 3px">
            {{ 'Abbreviations' | translate }}
          </div>
          <hr class="w-100 news-border mx-2" />
        </div>
      </div>

      <div>
        <div class="container fluid">
          <div class="row row-no-gutters">
            <div
              *ngFor="let acronym of incidentData.acronyms"
              class="px-1 py-2"
            >
              <div class="col acronym-tag">
                {{ acronym }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Tags section -->
  <div class="social-action px-4" *ngIf="!isMycontribution">
    <div class="like-comment" [ngClass]="shakeLike && 'shake-like'">
      <span
        class="icon-like"
        [ngClass]="checkclass ? 'active' : ''"
        (click)="updateFeedsocialData(incidentData._id, true)"
      ></span>
    </div>
    <!-- <div
      *ngIf="
        incidentData.isOpenInteraction ||
        incidentData.industry === logedUserData.industry
      "
    >
      <span
        class="icon-comment"
        [routerLink]="['/comments/' + incidentData._id]"
      ></span>
    </div> -->
    <div class="mr-auto">
      <span
        (click)="Sharemodel(sharemodel, incidentData)"
        class="icon-forward share"
      ></span>
    </div>
    <!-- <div [ngClass]="pulse && 'pulse-hover'">
      <span
        class="icon-heart"
        [ngClass]="incidentData.isFavorite ? 'active' : ''"
        (click)="updateFeedsocialData(incidentData._id, false)"
      ></span>
    </div> -->
  </div>
  <!-- social ison section -->
  <div
    class="social-action-count px-4 pb-4"
    style="border-bottom: none !important"
  >
    <div *ngIf="!isMycontribution">
      <span
        class="like-count"
        [ngClass]="incidentData.youLiked ? 'active' : ''"
      >
        <strong>{{ incidentData.likes ? incidentData.likes : '0' }}</strong>
        {{
          incidentData.likes == 1 ? ('Like' | translate) : ('Likes' | translate)
        }}
      </span>
    </div>
    <div class="mr-auto" *ngIf="!isMycontribution">
      <span class="comments-count">
        <strong>{{
          incidentData.commentCount ? incidentData.commentCount : '0'
        }}</strong>
        {{
          incidentData.commentCount == 1
            ? ('Comment' | translate)
            : ('Comments' | translate)
        }}
      </span>
    </div>
  </div>
  <!-- Social Like Comment Block End -->
  <!-- <div
    class="comment-row comment-with-icon px-3 pt-0 border-0"
    *ngIf="!isMycontribution"
  >
    <div class="mr-2">
      <picture class="comment-img">
        <img
          [src]="logedUserData.photo"
          alt="uploadedImage"
          (error)="img.src = uploadedImage"
          #img
        />
      </picture>
    </div>
    <div class="comment-input w-100 mr-3">
      <div class="form-group mb-0">
        <label class="sr-only" for="name">Email</label>
        <input
          class="form-control"
          placeholder="Add a Comment.."
          type="text"
          id="comment"
          [(ngModel)]="userComment"
        />
      </div>
    </div>
    <div class="send-icon" *ngIf="userComment">
      <span
        class="icon-comment-two icon-pink-color"
        (click)="addComment(incidentData._id)"
      ></span>
    </div>
  </div>
  <div class="px-3" *ngIf="inputData === 'give solution' || !isMycontribution">
    <button
      (click)="Solutionmodels(solution)"
      type="submit"
      class="btn btn-secondary w-100 mb-4"
    >
      Give Solution
    </button>
  </div> -->
</div>

<div class="custom-model">
  <ng-template #solution>
    <div class="modal-body-wrapper">
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="solutionRef.hide()"
      >
        <span aria-hidden="true">×</span>
      </button>
      <div class="common-form text-center">
        <h2 class="text-center form-title">
          {{ 'Give Solution' | translate }}
        </h2>
        <app-give-solutions
          (solutionData)="getSolutionData($event)"
          [inputData]="incidentTitle"
          [model]="solutionRef"
        >
        </app-give-solutions>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #postdetailpopup>
  <app-view-media
    [modal]="modalRef"
    [modalData]="incidentDetail"
  ></app-view-media>
</ng-template>

<div class="custom-model">
  <ng-template #sharemodel>
    <div class="modal-body-wrapper">
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="shareRef.hide()"
      >
        <span aria-hidden="true">×</span>
      </button>
      <div class="common-form p-4">
        <h2 class="text-center form-title mt-md-4 mt-2 mb-3">
          {{ 'Share' | translate }}
        </h2>
        <div class="feedheader p-0">
          <div class="feed-user p-0 d-flex align-items-center">
            <picture class="profile-img">
              <img
                *ngIf="shareIncidentData.secondaryAuthor; else author"
                [src]="shareIncidentData.secondaryAuthor?.photo"
                alt="no-img"
                (error)="img.src = uploadedImage"
                #img
              />
              <ng-template #author>
                <img
                  [src]="shareIncidentData.author?.photo"
                  alt="no-img"
                  (error)="img.src = uploadedImage"
                  #img
                />
              </ng-template>
            </picture>
            <div class="ml-3 mr-auto">
              <h4
                *ngIf="shareIncidentData.secondaryAuthor; else authorUsername"
                class="profile-name font-semibold mb-0"
              >
                {{
                  shareIncidentData.secondaryAuthor.username
                    ? shareIncidentData.secondaryAuthor.username
                    : '--'
                }}
              </h4>
              <ng-template #authorUsername>
                <h4 class="profile-name font-semibold mb-0">
                  {{
                    shareIncidentData.author.fakeUsername
                      ? shareIncidentData.author.fakeUsername
                      : '--'
                  }}
                </h4>
              </ng-template>
              <p
                *ngIf="
                  !shareIncidentData.isAsrs && !shareIncidentData.isTsb;
                  else postdate
                "
                class="post-date mb-0"
              >
                {{ shareIncidentData.createdAt | date : 'dd MMM yyyy' }}
              </p>
              <ng-template #postdate>
                <p class="post-date mb-0">
                  {{ shareIncidentData.postdate | date : 'dd MMM yyyy' }}
                </p>
              </ng-template>
            </div>
          </div>
          <p
            style="font-family: sans-serif"
            class="font-bold mt-2 mb-0 profile-desc"
          >
            {{
              shareIncidentData.incidentTitle
                ? shareIncidentData.incidentTitle
                : ''
            }}
          </p>
          <!-- <a class="text-black my-md-4 my-3 d-block share-link line-height-normal" target="_blank" href="http:dicerra.com/funnaymunoz7923242848-0/hei53554w/
fdjfofdsjfjp4334/?fsfsf=winterfs">http:dicerra.com/funnaymunoz7923242848-0/hei53554w/
                        fdjfofdsjfjp4334/?fsfsf=winterfs</a> -->
          <!-- <div class="text-center">
                        <button class="btn  btn-transparent d-inline-flex align-items-center copy-btn font-bold"><span
                                class="icon-copy mr-3 pink-color-icon "></span>Copy link</button>
                        <p class="font-bold my-3 my-md-4 ">OR Share with</p>
                    </div> -->
          <div class="d-flex justify-content-center mt-5">
            <share-buttons
              [theme]="'modern-light'"
              [include]="[
                'facebook',
                'twitter',
                'whatsapp',
                'linkedin',
                'copy'
              ]"
              [show]="9"
              [size]="1"
              [autoSetMeta]="false"
              [url]="
                'https://staging-web.dicerra.com/detailpage/' +
                shareIncidentData._id
              "
            >
            </share-buttons>
            <!-- <ul class="social-list with-color list-unstyled list">
                            <li class="facebook">
                                <a class=" mx-1">
                                    <span class="icon-facebook"> </span>
                                </a>
                            </li>
                            <li class="twitter">
                                <a class=" mx-1">
                                    <span class="icon-twitter"> </span>
                                </a>
                            </li>
                            <li class="linkedin">
                                <a class=" mx-1">
                                    <span class="icon-linkedin"> </span>
                                </a>
                            </li>
                            <li class="youtube">
                                <a class=" mx-1">
                                    <span class="icon-youtube"></span>
                                </a>
                            </li>
                            <li class="instagram">
                                <a class=" mx-1">
                                    <span class="icon-instagram"></span>
                                </a>
                            </li>
                        </ul> -->
          </div>
          <div>
            <!-- <button class="btn btn-secondary w-100 mt-md-5 mt-4 mb-2">Share this incident</button> -->
          </div>
        </div>
        <!-- feed header -->
      </div>
    </div>
  </ng-template>
</div>
<!-- Share popup -->
