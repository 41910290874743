import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PaginationService {
  constructor() {}
  nextPageCal(pageNumber, size, total) {
    let page = Number(pageNumber);
    let perPage = Number(size);
    let count = Number(total);
    let divideCount = count / perPage;
    let nextPage = Math.ceil(divideCount);
    if (page < nextPage) return page + 1;
    return 0;
  }
}
