export const messagesFr = {
  ERROR: {
    REQUIRED: {
      //register
      fullName: 'Entrez le nom complet',
      email: 'Entrez l addresse courriel',
      password: 'Entrez le mot de passe',
      confirmPassword: 'Confirmez le mot de passe',
      agreeTermsAndConditions: 'Cochez la case',
      //login
      emailOrLoginId: 'Entrez votre e-mail ou votre identifiant de connexion',
      //forget
      forgetEmail: 'Entrez l addresse courriel enregistré',
      userRecommendDomain: 'Entrez l adresse courriel du domaine recommandé',
      hospital: 'Entrez l hôpital',
      departmentOfHealth: 'Entrez le département de soins de santé',
      designation: 'Sélectionnez une désignation',
      role: 'Entrez le rôle',
      yearOfExp: 'Entrez le nombre d années d expérience',
      DOB: 'Entrez la date de naissance',
      personalEmail: 'Entrez l addresse courriel personelle',
      contactNumber: 'Entrez le numéro de contact',
      oldPassword: 'Entrez l ancien mot de passe',
      newPassword: 'Entrez le nouveau mot de passe',
      confirmNewPassword: 'Entrez la confirmation du nouveau mot de passe',
      domainEmail: 'Entrez l addresse courriel du domaine de soins de santé',
      selectDomain: 'Sélectionnez un domaine',
      healthcareRegion: 'Sélectionnez une région des soins de santé',
      healthcareSubRegion: 'Sélectionnez une sous-région de soins de santé',
      departmentOfHealthCare: 'Sélectionnez un département de soins de santé',
      type: 'Sélectionnez une catégorie',
      expertise: 'Sélectionnez une compétance',
      placeOfService: 'Sélectionnez une place de service',
      region: 'Sélectionnez une région  ',
      airline: 'Sélectionnez une compagnie aérienne',
      incidentType: 'Sélectionnez un type d incident',
      incidentTitle: 'Entrez le titre de l incident',
      incidentDescription: 'Entrez la description de l incident',
      incidentReason: 'Entrez la raison de l incident',
      deleteDescription: 'Entrez la description',
      solutionTitle: 'Entrez le titre de la solution',
      //Reach Us
      name: 'Entrez le nom',
      message: 'Entrez le message',
      patientAge: 'Entrez l âge du patient',
      healthcareRegionCanada: 'Sélectionnez une région des soins de santé',
      provinceCanada: 'Sélectionnez une province',
    },
    PATTERN: {
      email: 'Entrez une addresse courriel valide',
      userRecommendDomain:
        'Entrez l addresse courriel du domaine recommandé valide',
      password:
        'Le mot de passe nécessite au moins 8 caractères avec au minimum une lettre majuscule, une lettre minuscule et un chiffre',
      newPassword:
        'Saisissez au moins 8 caractères dont une majuscule, un chiffre et un caractère minuscule',
      personalEmail: 'Entrez une addresse courriel personnelle valide',
    },
    MINLENGTH: {
      contactNumber: 'Le numéro de portable doit avoir au moins 10 chiffres',
    },
    CUSTOM: {},
  },
};
