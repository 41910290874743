import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

// Constants
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { GlobalLanguageService } from 'src/app/common/services/service/global-language.service';
import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: [],
})
export class FooterComponent implements OnInit {
  URLConstants = URLConstants;
  public currentYear: any = new Date().getFullYear();
  languageArr = [
    { name: 'English', value: 'en' },
    { name: 'Deutsch', value: 'de' },
    { name: 'French', value: 'fr' },
    // Add more languages if needed
  ];
  public selectedLanguage: any;
  constructor(
    public router: Router,
    public globalLanguageService: GlobalLanguageService,
    public localStorageService: LocalStorageService,
    private location: Location
  ) {}

  ngOnInit() {}
  onLanguageSelection(event) {
    const selectedValue = event.value;
    this.globalLanguageService.sendGlobalLanguage(selectedValue);
  }

  setIndustry(value) {
    this.localStorageService.setToken('industry', value);
    if (this.router.url === '/about') {
      this.location.go(this.location.path());
      window.location.reload();
    } else {
      // If not on the '/about' route, navigate without reloading
      this.router.navigate(['./about']);
    }
  }
}
