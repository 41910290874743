export const messagesDe = {
  ERROR: {
    REQUIRED: {
      //register
      fullName: 'Vollständigen Namen eingeben',
      email: 'E-Mail eingeben',
      password: 'Passwort eingeben',
      confirmPassword: 'Passwort bestätigen',
      agreeTermsAndConditions: 'Kontrollkästchen auswählen',
      //login
      emailOrLoginId: 'Geben Sie E-Mail oder LoginID ein',
      //forget
      forgetEmail: 'Registrierte E-Mail eingeben',
      userRecommendDomain: 'Empfohlene Domain-E-Mail eingeben',
      hospital: 'Krankenhaus eingeben',
      departmentOfHealth: 'Abteilung für Gesundheitswesen eingeben',
      yearOfExp: 'Berufserfahrung in Jahren eingeben',
      DOB: 'Geburtsdatum eingeben',
      personalEmail: 'Persönliche E-Mail eingeben',
      contactNumber: 'Kontaktnummer eingeben',
      oldPassword: 'Altes Passwort eingeben',
      newPassword: 'Neues Passwort eingeben',
      confirmNewPassword: 'Neues Passwort bestätigen',
      domainEmail: 'E-Mail des Gesundheitsbereichs eingeben',
      selectDomain: 'Domain auswählen',
      healthcareRegion: 'Gesundheitsregion auswählen',
      healthcareSubRegion: 'Gesundheits-Unterregion auswählen',
      departmentOfHealthCare: 'Abteilung für Gesundheitswesen auswählen',
      type: 'Typ auswählen',
      expertise: 'Expertise auswählen',
      placeOfService: 'Dienstort auswählen',
      region: 'Region auswählen',
      airline: 'Fluggesellschaft auswählen',
      incidentType: 'Vorfalltyp auswählen',
      incidentTitle: 'Vorfalltitel eingeben',
      incidentDescription: 'Vorfallbeschreibung eingeben',
      incidentReason: 'Grund für den Vorfall eingeben',
      deleteDescription: 'Beschreibung eingeben',
      solutionTitle: 'Lösungstitel eingeben',
      //Reach Us
      name: 'Namen eingeben',
      message: 'Nachricht eingeben',
      patientAge: 'Patientenalter eingeben',
    },
    PATTERN: {
      email: 'Gültige E-Mail-Adresse eingeben',
      userRecommendDomain: 'Gültige empfohlene Domain-E-Mail-Adresse eingeben',
      password:
        'Das Passwort erfordert mindestens 8 Zeichen mit mindestens einem Großbuchstaben, einem Kleinbuchstaben und einer Ziffer',
      newPassword:
        'Geben Sie mindestens 8 Zeichen mit einem Großbuchstaben, einer Ziffer und einem Kleinbuchstaben ein',
      personalEmail: 'Gültige persönliche E-Mail-Adresse eingeben',
    },
    MINLENGTH: {
      contactNumber: 'Mobilnummer muss mindestens 10 Ziffern haben',
    },
    CUSTOM: {},
  },
};
