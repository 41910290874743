import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class CloseModalService {
  public closeModal = new Subject<any>();
  constructor() {}

  sendCloseEvent(flag: boolean) {
    this.closeModal.next({ flag: flag });
  }

  getCloseModalService(): Observable<any> {
    return this.closeModal.asObservable();
  }
}
