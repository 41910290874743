export const messages = {
  ERROR: {
    REQUIRED: {
      //register
      fullName: 'Enter full name',
      email: 'Enter Email',
      password: 'Enter Password',
      confirmPassword: 'Confirm Password',
      agreeTermsAndConditions: 'Select Checkbox',
      //login
      emailOrLoginId: 'Enter Email or LoginID',
      //forget
      forgetEmail: 'Enter registed Email',
      userRecommendDomain: 'Enter Recommend Domain Email',
      hospital: 'Enter Hospital',
      departmentOfHealth: 'Enter Department Of Health',
      designation: 'Select Designation',
      role: 'Enter Role',
      yearOfExp: 'Enter Year Of Experience',
      DOB: 'Enter Date of Birth',
      personalEmail: 'Enter Personal Email',
      contactNumber: 'Enter Contact Number',
      oldPassword: 'Enter Old Password',
      newPassword: 'Enter New Password',
      confirmNewPassword: 'Enter Confirm NewPassword',
      domainEmail: 'Enter Health Care Domain Email',
      selectDomain: 'Select Domain',
      healthcareRegion: 'Select Healthcare Region',
      healthcareSubRegion: 'Select Healthcare Sub-Region',
      departmentOfHealthCare: 'Select Department Of HealthCare',
      type: 'Select Type',
      expertise: 'Select Expertise',
      placeOfService: 'Select Place of Service',
      region: 'Select Region',
      airline: 'Select Airline',
      incidentType: 'Select incident type',
      incidentTitle: 'Enter incident title',
      incidentDescription: 'Enter incident description',
      incidentReason: 'Enter incident reason',
      deleteDescription: 'Enter description',
      solutionTitle: 'Enter solution title',
      //Reach Us
      name: 'Enter name',
      message: 'Enter message',
      patientAge: 'Enter patient age',
      healthcareRegionCanada: 'Select Healthcare Region',
      provinceCanada: 'Select Province',
    },
    PATTERN: {
      email: 'Enter Valid Email Address',
      userRecommendDomain: 'Enter Valid Recommend Domain Email Address',
      password:
        'Password requires at least 8 characters with a minimum of one uppercase letter, one lowercase letter and one digit',
      newPassword:
        'Enter atleast 8 characters with one uppercase, one digit and one lower character',
      personalEmail: 'Enter Valid Personal Email Address',
    },
    MINLENGTH: {
      contactNumber: 'Mobile must have atleast 10 digits',
    },
    CUSTOM: {},
  },
};
