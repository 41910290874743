import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TabSelectionService {
  private selectedTabSubject = new BehaviorSubject<string>('');

  selectedTab$ = this.selectedTabSubject.asObservable();

  selectTab(tabName: string) {
    this.selectedTabSubject.next(tabName);
  }
}
